import * as Yup from "yup";
import { Formik } from "formik";
import { Box, FormHelperText } from "@mui/material";
import _ from "lodash";
import "./style.css";
import AuthContext from "contexts/authContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginService } from "services/Auth";
import { useLocation } from "react-router-dom";
import Loading from "component/Loading";
import Header from "component/Header";
import Footer from "component/Footer";
import { loginImage } from "assets";
import { Paths } from "routes";
import { hashSHA256 } from "utils";

const Login = () => {
  const { changeAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);

  return (
    <div className="login-wrapper">
      <Header isLogin={true} />
      {loader && <Loading />}
      <div className={`login ${loader ? "ui-hide" : ""}`}>
        <div className="card-wrapper">
          <div className="card">
            <div className="left-section">
              <div className="title">Log in</div>
              <Formik
                initialValues={{
                  email: _.get(location, "state.email", ""),
                  password: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                  password: Yup.string()
                    .max(255)
                    .required("Password is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    setLoader(true);
                    const userData = await loginService({
                      Email: values.email,
                      Password: values.password,
                    });

                    if (userData) {
                      changeAuth({ isLoggedIn: true, userData: userData.data });
                      setStatus({ success: true });
                      setSubmitting(false);
                      navigate({
                        pathname: "/",
                        state: {
                          isRedirectFromRegister: false,
                          isRedirectFromLogin: true,
                        },
                      });

                      try {
                        window.ttq.identify({
                          email: hashSHA256(values.email), // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
                        });
                      } catch (err) {
                        console.log("error in tiktok pixel");
                      }
                      try {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          UserId: userData.data.userId,
                        });
                      } catch (err) {
                        console.log("error in data layer");
                      }
                    } else {
                      setStatus({ success: false });
                      setErrors({
                        submit:
                          "Email or password is invalid, please check and try again",
                      });
                    }
                  } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                    if (
                      _.get(err, "response.data.code", "") ===
                      "otp_verification_required"
                    ) {
                      navigate(Paths.otpVerifying);
                    }
                    setErrors({
                      submit: _.get(
                        err,
                        "response.data.title",
                        "Something went wrong"
                      ),
                    });
                    setSubmitting(false);
                  } finally {
                    setLoader(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div>
                      <input
                        placeholder="Email Address"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                      {Boolean(touched.email && errors.email) && (
                        <FormHelperText error>
                          {touched.email && errors.email}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mt-20">
                      <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                      />
                      {Boolean(touched.password && errors.password) && (
                        <FormHelperText error>
                          {touched.password && errors.password}
                        </FormHelperText>
                      )}
                    </div>
                    {errors.submit && (
                      <Box>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <button
                        className="btn-login hover-zoom"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Log in
                      </button>
                    </Box>
                  </form>
                )}
              </Formik>
              <div className="links">
                <div
                  className="link"
                  onClick={() => {
                    navigate(Paths.SignUp);
                  }}
                >
                  Create Account?
                </div>
                <div
                  className="link"
                  onClick={() => navigate(Paths.ForgotPassword)}
                >
                  Forgot password?
                </div>
              </div>
            </div>
            <div className="right-section">
              <img src={loginImage} alt="loginImage" />
            </div>
          </div>
        </div>
      </div>
      <Footer className={`${loader ? "ui-hide" : ""}`} />
    </div>
  );
};

export default Login;
