import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLesson } from "services/AI";
import _ from "lodash";
import Loading from "component/Loading";
import { saveAs } from "file-saver";
import HtmlDocx from "html-docx-js/dist/html-docx";
import { pdfIcon, wordIcon } from "assets";
import { pdfExport } from "pages/StudyWorksheet";

let fetchIntervalLesson = null;
function LessonKeywords() {
  let { jobid } = useParams();
  const [data, setData] = useState({});
  const [status, setStatus] = useState("wait");
  const [loading, setLoading] = useState(true);
  const [openBuildingInProgressDialog, setOpenBuildingInProgressDialog] =
    useState(false);

  useEffect(() => {
    if (status === "done") {
      if (openBuildingInProgressDialog) setOpenBuildingInProgressDialog(false);
    }
    if (status === "in progress") {
      setOpenBuildingInProgressDialog(true);
    }
  }, [status]);

  const getData = async () => {
    await getLesson(jobid).then((res) => {
      setData(res.data);
      setStatus(_.get(res, "data.status", ""));
      if (_.get(res, "data.status", "") === "done") {
        if (fetchIntervalLesson) {
          clearInterval(fetchIntervalLesson);
        }
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getData();
    fetchIntervalLesson = setInterval(() => {
      getData();
    }, 3000); // 5sec
    return () => {
      if (fetchIntervalLesson) {
        clearInterval(fetchIntervalLesson);
      }
    };
  }, []);

  if (loading) return <Loading />;

  const handleExportWord = async () => {
    const input = document.getElementById("page-content");

    const contentHTML = input.innerHTML;

    var converted = HtmlDocx.asBlob(contentHTML);
    saveAs(
      converted,
      `Keywords - ${_.get(data, "lessenDetails.title", "")}.docx`
    );
  };

  const styleTable = {
    width: "100%",
    borderCollapse: "collapse",
    fontSize: "16px",
    pageBreakInside: "avoid",
  };

  const styleTh = {
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "8px",
    border: "1px solid #000000",
    webkitPrintColorAdjust: "exact",
    height: "3em",
    lineHeight: "1.5em",
    minHeight: "3em",
    wordBreak: "break-word",
  };

  const styleTd = {
    padding: "8px",
    border: "1px solid #000000",
    fontSize: "16px",
    pageBreakInside: "avoid",
    pageBreakAfter: "auto",
    height: "3em",
    lineHeight: "1.5em",
    minHeight: "3em",
    wordBreak: "break-word",
  };

  const styleTr = {
    pageBreakInside: "avoid",
    pageBreakAfter: "auto",
  };

  const styleGreenColor = {
    background: "#d2fcd7",
  };
  const styleTableWrapper = {
    padding: "20px 0px",
  };

  return (
    <>
      <div className="text-center" style={{ marginTop: "8px" }}>
        <label htmlFor="">Download as: </label>
        <img
          src={pdfIcon}
          onClick={() => {
            setLoading(true);
            pdfExport(
              `Keywords - ${_.get(data, "lessenDetails.title", "")}.pdf`
            ).then(() => setLoading(false));
          }}
          alt="pdf"
          className="cursor-pointer"
          height={28}
        />
        <img
          src={wordIcon}
          onClick={() => handleExportWord()}
          alt="word"
          className="cursor-pointer"
          height={28}
        />
      </div>
      <div
        id="page-content"
        style={{
          width: "210mm",
          minWidth: "210mm",
          margin: "auto",
          fontFamily: "'Open Sans', sans-serif",
          padding: "0px 30px",
          color: "#000000",
          letterSpacing: "normal",
        }}
      >
        <h1
          style={{
            fontSize: "2em",
            fontWeight: "bold",
            fontFamily: "'Open Sans', sans-serif",
            textAlign: "center",
          }}
        >
          {_.get(data, "lessenDetails.title", "")}
        </h1>
        <div style={{ ...styleTableWrapper }}>
          <table style={{ ...styleTable }}>
            <tr style={{ ...styleTr }}>
              <th style={{ ...styleTh, width: "19%", ...styleGreenColor }}>
                Term
              </th>
              <th style={{ ...styleTh, width: "33%", ...styleGreenColor }}>
                Definition
              </th>
              <th style={{ ...styleTh, width: "15%", ...styleGreenColor }}>
                Synonyms
              </th>
              <th style={{ ...styleTh, width: "33%", ...styleGreenColor }}>
                Example Sentence
              </th>
            </tr>
          </table>

          {_.map(_.get(data, "keyWords.content", []), (term) => (
            <table style={{ ...styleTable }}>
              <tr style={{ ...styleTr }}>
                <td style={{ ...styleTd, width: "19%" }}>
                  {_.isObject(term) ? term.keyword : term}
                </td>
                <td style={{ ...styleTd, width: "33%" }}>
                  {_.get(term, "definition", "")}
                </td>
                <td style={{ ...styleTd, width: "15%" }}>
                  {_.get(term, "synonyms", "")}
                </td>
                <td style={{ ...styleTd, width: "33%" }}>
                  {_.get(term, "sentence", "")}
                </td>
              </tr>
            </table>
          ))}

          <div style={{ textAlign: "center", padding: "15px" }}>
            Generated on{" "}
            <a
              href="https://lessen.ai/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#0075ff", textDecoration: "none" }}
            >
              lessen.ai
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default LessonKeywords;
