import { forwardRef, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import "./style.scss";
import {
  dialogCloseIcon,
  dragIcon,
  removeSectionIcon,
  whitePlusIcon,
} from "assets";
import _ from "lodash";
import { getSlideContentList } from "pages/Lesson";
import LessonSummeryItem from "component/LessonSummeryItem";
import DeleteDialog from "dialogs/DeleteDialog";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";

const TYPE = "SLIDE";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DraggableSlide = forwardRef(({ ...props }, ref) => {
  const refSlide = useRef(null);

  const [, drag] = useDrag({
    type: TYPE,
    item: { id: props.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      // props.setDraggingItem(null);
    },
  });

  const [, drop] = useDrop({
    accept: TYPE,
    hover: (item, monitor) => {
      if (!refSlide.current) {
        return;
      }

      const dragIndex = item.id;
      const hoverIndex = props.id;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = refSlide.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return;
      }

      props.moveSlide(dragIndex, hoverIndex);
      item.id = hoverIndex;
    },
  });

  drag(drop(refSlide));

  const dragSectionIconUI = (
    <img src={dragIcon} alt="dragIcon" className="drag-action" />
  );

  const deleteSlideIconUI = (index) => (
    <DeleteDialog
      className="delete-confirmation"
      deleteback={() => {
        props.onDeleteSlide(index);
      }}
      text={"Are you sure you want to delete selected slide?"}
      subText={null}
    >
      <img src={removeSectionIcon} alt="removeSectionIcon" />
    </DeleteDialog>
  );

  return (
    <div
      className="lesson-summery-item-wrapper"
      ref={refSlide}
      onClick={props.onClick}
    >
      {dragSectionIconUI}
      <LessonSummeryItem
        si={props.slide}
        i={props.index}
        titleEdit={true}
        onTitleChange={(newTitle) => {
          if (props.onTitleChange) props.onTitleChange(newTitle);
        }}
      />
      {deleteSlideIconUI(props.index)}
    </div>
  );
});

const EditSlidesDialog = (props) => {
  const {
    open,
    setOpen,
    slides,
    setSlides,
    onSlideClick,
    addNewSlide,
    onTitleChange,
  } = props;

  const moveSlide = (fromIndex, toIndex) => {
    const from = slides[fromIndex];
    const newSections = slides.slice();
    newSections.splice(fromIndex, 1);
    newSections.splice(toIndex, 0, from);

    setSlides(newSections);
  };

  const handleSlideClick = (index) => {
    onSlideClick(index);
    setOpen(false);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (setOpen) setOpen(false);
  };

  const onDeleteSlide = (index) => {
    const slidesData = _.cloneDeep(slides);
    slidesData.splice(index, 1);
    setSlides(slidesData);
  };

  useEffect(() => {
    if (open) {
      if (!window.history.state?.popupOpen)
        window.history.pushState({ popupOpen: true }, "");
    } else {
      if (window.history.state?.popupOpen) {
        window.history.back();
      }
    }
    const handleBackButton = (e) => {
      if (open) {
        handleClose();
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    };
    window.addEventListener("popstate", handleBackButton);
    return () => window.removeEventListener("popstate", handleBackButton);
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: "32px",
            overflowY: "unset",
            maxHeight: "CALC(100vh - 120px)",
            padding: "18px 0px",
          },
        }}
      >
        <DialogContent sx={{ borderRadius: "32px" }}>
          <DialogTitle sx={{ padding: 0 }}>
            <img
              src={dialogCloseIcon}
              alt="dialogCloseIcon"
              className="dialogCloseIcon"
              style={{
                position: "absolute",
                top: "-46px",
                right: "-74px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="edit-slides-dialog">
              <DndProvider backend={HTML5Backend}>
                {_.map(getSlideContentList(slides), (si, i) => (
                  <DraggableSlide
                    slide={si}
                    index={i}
                    key={si.id}
                    id={i}
                    moveSlide={moveSlide}
                    onClick={() => handleSlideClick(i)}
                    onDeleteSlide={onDeleteSlide}
                    onTitleChange={(newTitle) =>
                      onTitleChange(newTitle, i, si.titleId, si.titleIndex)
                    }
                  />
                ))}
              </DndProvider>
              <div className="btn-wrapper">
                <div
                  className="add-new-section-btn blue-btn max-191"
                  onClick={() => {
                    addNewSlide();
                    // addSectin(sections.length - 1, "text", false, "", "");
                  }}
                >
                  <img src={whitePlusIcon} alt="whitePlusIcon" />
                  <span>Add New Slide</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditSlidesDialog;
