export const capitalizeFirstChar = (str) => {
  if (str && str.length > 1) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  }
  return str;
};

export const extractImages = (jsonData) => {
  // Initialize an empty array to store image URLs
  let imageArray = [];

  // Loop through each slide in the JSON data
  jsonData.forEach((slide) => {
    // Loop through each section in the slide
    slide.sections.forEach((section) => {
      // Check if the section contains an image
      if (section.hasOwnProperty("image")) {
        // Add the image URL to the array
        imageArray.push(section.image);
      }
    });
  });

  // Return the array of image URLs
  return imageArray;
};

export const hashSHA256 = async (message) => {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};
