import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { deleteLesson, getLessons } from "services/AI";
import _ from "lodash";
import { timeDifferenceAgo } from "utils/timeAgo";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import LoadingDots from "component/LoadingDots";
import {
  checkIcon,
  closeBlueIcon,
  editGridIcon,
  searchLessonIcon,
  uncheckIcon,
} from "assets";
import DeleteDialog from "dialogs/DeleteDialog";

function RecentLessons() {
  const [recentLessons, setRecentLessons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedList, setSelectedList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const navigate = useNavigate();

  const debouncedGetRecentLessons = useCallback(
    _.debounce(async (searchKey) => {
      setLoading(true);
      const lessen = await getLessons(
        undefined,
        0,
        searchKey,
        "lastOpened",
        "DESC"
      );
      setRecentLessons(_.get(lessen, "data", []));
      setLoading(false);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedGetRecentLessons(searchKey);
  }, [searchKey, debouncedGetRecentLessons]);

  const handleDelete = async () => {
    setLoading(true);
    if (selectedList.length > 0) {
      const promiseList = [];
      for (let d = 0; d < selectedList.length; d++) {
        promiseList.push(deleteLesson(selectedList[d]));
      }
      await Promise.all(promiseList);
      const lessen = await getLessons(
        undefined,
        0,
        searchKey,
        "lastOpened",
        "DESC"
      );
      setRecentLessons(_.get(lessen, "data", []));
      await new Promise((res) => setTimeout(res, 100));
      setSelectedList([]);
    }
    setLoading(false);
  };

  return (
    <div className="recent-lessons-container">
      <div className="recent-lessons">
        {selectedList.length > 0 && isEdit && (
          <DeleteDialog deleteback={handleDelete}>
            <div className="btn-delete">Delete ({selectedList.length})</div>
          </DeleteDialog>
        )}
        <div className="recent-lessons-search-wrapper">
          <div className="recent-lessons-search">
            <img src={searchLessonIcon} alt="searchLessonIcon" />
            <input
              placeholder="Search for a specific lesson title"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            ></input>
          </div>
        </div>
        <div class="flex-table">
          <div class="flex-row flex-header">
            <div class="flex-cell lesson-item">Recent Lessons</div>
            {window.innerWidth > 600 && (
              <div class="flex-cell last-open">Last opened</div>
            )}
            <div class="flex-cell edit d-flex">
              <img
                src={isEdit ? closeBlueIcon : editGridIcon}
                alt="editGridIcon"
                onClick={() => setIsEdit(!isEdit)}
              />
            </div>
          </div>
          {_.map(recentLessons, (rl) => (
            <div
              class="flex-row data-row"
              key={rl.jobId}
              onClick={() => navigate(Paths.Lesson.replace(":jobid", rl.jobId))}
            >
              {window.innerWidth < 600 ? (
                <div class="flex-cell mobile-view">
                  <div class="lesson-item">{rl.title}</div>
                  <div class="last-open">
                    Opened {timeDifferenceAgo(rl.lastOpened)}
                  </div>
                </div>
              ) : (
                <>
                  <div class="flex-cell lesson-item">{rl.title}</div>
                  <div class="flex-cell last-open">
                    Opened {timeDifferenceAgo(rl.lastOpened)}
                  </div>
                </>
              )}
              <div class="flex-cell edit">
                {isEdit && (
                  <>
                    {selectedList.indexOf(rl.jobId) === -1 ? (
                      <img
                        src={uncheckIcon}
                        alt="uncheckIcon"
                        onClick={(e) => {
                          e.stopPropagation();
                          const newList = _.cloneDeep(selectedList);
                          newList.push(rl.jobId);
                          setSelectedList(newList);
                        }}
                      />
                    ) : (
                      <img
                        src={checkIcon}
                        alt="checkIcon"
                        onClick={(e) => {
                          e.stopPropagation();
                          let newList = _.cloneDeep(selectedList);
                          newList = _.filter(newList, (l) => l !== rl.jobId);
                          setSelectedList(newList);
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        {recentLessons.length === 0 && !loading && searchKey.length > 0 && (
          <div className="search-criteria">
            There are no lessons matching the search criteria.
          </div>
        )}
        {loading && <LoadingDots />}
      </div>
    </div>
  );
}

export default RecentLessons;
