import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import "./style.scss";
import { dialogCloseIcon } from "assets";
import { createCheckoutSession } from "services/Auth";
import _ from "lodash";
import Loading from "component/Loading";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UnlimtedAccessForDialog = (props) => {
  const { open, setOpen } = props;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (open) {
      if (!window.history.state?.popupOpen)
        window.history.pushState({ popupOpen: true }, "");
    } else {
      if (window.history.state?.popupOpen) {
        window.history.back();
      }
    }
    const handleBackButton = (e) => {
      if (open) {
        handleClose();
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    };
    window.addEventListener("popstate", handleBackButton);
    return () => window.removeEventListener("popstate", handleBackButton);
  }, [open]);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (setOpen) setOpen(false);
  };

  const gotoCheckout = async () => {
    setLoader(true);
    const response = await createCheckoutSession("monthly", false, false, true);
    const url = _.get(response, "data.url", "");
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { borderRadius: "32px", overflowY: "unset", maxWidth: "660px" },
        }}
      >
        <DialogContent sx={{ borderRadius: "32px" }}>
          <DialogTitle sx={{ padding: 0 }}>
            <img
              src={dialogCloseIcon}
              alt="dialogCloseIcon"
              className="dialogCloseIcon"
              style={{
                position: "absolute",
                top: "-46px",
                right: "-74px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="unlimted-access-dialog">
              {loader && (
                <div className="unlimted-access-dialog-loading-wrapper">
                  <Loading
                    className="unlimted-access-dialog-loading"
                    text=" "
                  />
                </div>
              )}
              <label>Unlimited access for £1</label>
              <div className="sub-title">
                For £1 - you can enjoy 3 days of unlimited access - as if you
                were a premium user.
              </div>
              <div className="item-title">Here is what you get:</div>
              <ul>
                <li>Unlimited Presentations</li>
                <li>
                  Edit AI powered slides with content and pictures of your
                  choice
                </li>
                <li>
                  Relevant AI generated images to suit the topic of your slides
                </li>
                <li>
                  Save up to 10 hours per week, by having AI plan your lessons
                </li>
              </ul>
              <div className="btn-wrapper">
                <div className="btn blue-btn btn-v-big" onClick={gotoCheckout}>
                  Start your Trial
                </div>
                <div className="trial-notes">Cancel anytime</div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UnlimtedAccessForDialog;
