export const studyLevels = [
  { id: 1, name: "KS3", code: "KS3" },
  { id: 2, name: "GCSE", code: "GCSE" },
  { id: 3, name: "A LEVEL", code: "A-Level" },
];

export const gradeLevels = [
  { id: 1, name: "1st Grade", code: "1st Grade" },
  { id: 2, name: "2nd Grade", code: "2nd Grade" },
  { id: 3, name: "3rd Grade", code: "3rd Grade" },
  { id: 4, name: "4th Grade", code: "4th Grade" },
  { id: 5, name: "5th Grade", code: "5th Grade" },
  { id: 6, name: "6th Grade", code: "6th Grade" },
  { id: 7, name: "7th Grade", code: "7th Grade" },
  { id: 8, name: "8th Grade", code: "8th Grade" },
  { id: 9, name: "9th Grade", code: "9th Grade" },
  { id: 10, name: "10th Grade", code: "10th Grade" },
  { id: 11, name: "11th Grade", code: "11th Grade" },
  { id: 12, name: "12th Grade", code: "12th Grade" },
];
