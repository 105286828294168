import AuthContext from "contexts/authContext";
import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Paths } from "routes";

function AuthChecker(props) {
  const {
    children,
    mustAuth = false,
    mustNonAuth = false,
    onlyOutlet = false,
  } = props;
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (mustAuth && !auth.isLoggedIn) {
      navigate(Paths.Login);
    }
    if (mustNonAuth && auth.isLoggedIn) {
      navigate("/");
    }
  }, [auth]);

  if (onlyOutlet) return <Outlet />;
  return children;
}

export default AuthChecker;
