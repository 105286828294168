import React from "react";

function Loading(props) {
  const { text = "Loading..", className, noText = false } = props;
  return (
    <div className={`loading-container ${className}`}>
      <div className="loading">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      {!noText && <p className="loading-text">{text}</p>}
    </div>
  );
}

export default Loading;
