export const examBoards = [
  { id: 1, name: "Assessment and Qualifications Alliance (AQA)", code: "AQA" },
  {
    id: 2,
    name: "Oxford, Cambridge and RSA Examinations (OCR)",
    code: "OCR",
  },
  { id: 3, name: "Edexcel", code: "Edexcel" },
  { id: 4, name: "Welsh Joint Education Committee (WJEC)", code: "WJEC" },
  { id: 5, name: "Scottish Qualifications Authority (SQA)", code: "SQA" },
  {
    id: 6,
    name: "Council for the Curriculum, Examinations and Assessment (CCEA)",
    code: "CCEA",
  },
];
