import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import "./style.scss";
import { dialogCloseIcon, leftBlueIcon, lessonSummaryIcon } from "assets";
import { generateResource, getLessenResource } from "services/AI";
import { toast } from "react-toastify";
import _ from "lodash";
import Loading from "component/Loading";
import { Paths } from "routes";
import BuildingInProgressDialog from "dialogs/BuildingInProgressDialog";
import getVideoId from "get-video-id";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

var ResourcesViewDialogBlinkInterval;
const ResourcesViewDialog = (props) => {
  const { children, lessenTitle = "", lessenId, slideData = [] } = props;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [inputLink, setInputLink] = useState("");
  const [view, setView] = useState("resource-view");
  const [openBuildingInProgressDialog, setOpenBuildingInProgressDialog] =
    useState(false);
  const [buildingInProgressDialogText, setBuildingInProgressDialogText] =
    useState(
      "Your worksheets are being generated, please come back in a few minutes."
    );

  useEffect(() => {
    setView("resource-view");
    if (!open) {
      if (ResourcesViewDialogBlinkInterval) clearInterval();
    }
    if (open) {
      if (ResourcesViewDialogBlinkInterval)
        clearInterval(ResourcesViewDialogBlinkInterval);
      ResourcesViewDialogBlinkInterval = setInterval(() => {
        getAllResource(false);
      }, 3000);
    }

    return () => {
      clearInterval(ResourcesViewDialogBlinkInterval);
    };
  }, [open]);

  const getAllResource = async (withLoading = true) => {
    if (withLoading) setLoading(true);
    const resourcesRes = await getLessenResource(lessenId);
    setResources(_.get(resourcesRes, "data", []));
    if (withLoading) setLoading(false);
  };

  useEffect(() => {
    if (open && view === "resource-view") {
      getAllResource();
    }
  }, [open, view]);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (setOpen) setOpen(false);
  };
  const handleClickOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const resourceView = (
    <div className="wrapper">
      <div className="title">Differentiated Resources: {lessenTitle}</div>
      <div className="content">
        {loading ? (
          <Loading className="resource-loading" />
        ) : (
          <>
            {_.map(resources, (resource, index) => {
              let video_id = "";
              try {
                const { id } = getVideoId(_.get(resource, "url", ""));
                if (id) video_id = id;
              } catch (e) {
                console.log(e);
              }
              return (
                <div
                  className="resource-item"
                  onClick={() => {
                    if (_.get(resource, "status") !== "done") {
                      setOpenBuildingInProgressDialog(true);
                      setBuildingInProgressDialogText(
                        "Your worksheets are being generated, please come back in a few minutes."
                      );
                    } else
                      window.open(
                        Paths.StudyWorksheet.replace(":jobid", resource.jobId),
                        "_blank"
                      );
                  }}
                >
                  <div className="img-container">
                    <img
                      src={
                        video_id
                          ? `https://img.youtube.com/vi/${video_id}/hqdefault.jpg`
                          : lessonSummaryIcon
                      }
                      alt="lessonSummaryIcon"
                    />
                  </div>
                  <div className="name">
                    {_.get(resource, "status") !== "done" && (
                      <span className="status">
                        [{_.get(resource, "status")}]{" "}
                      </span>
                    )}
                    {_.get(resource, "name") || resource.jobId}
                  </div>
                </div>
              );
            })}
            {!(
              _.get(slideData, "lessenDetails.id", false) < 1073 &&
              !_.get(slideData, "homework.content", false)
            ) && (
              <div
                className="resource-item blue-item"
                onClick={() => {
                  if (!_.get(slideData, "homework.content", false)) {
                    setOpenBuildingInProgressDialog(true);
                    setBuildingInProgressDialogText(
                      "Your lesson homework are being generated, please come back in a few minutes."
                    );
                  } else
                    window.open(
                      Paths.Homework.replace(":jobid", lessenId),
                      "_blank"
                    );
                }}
              >
                <img src={lessonSummaryIcon} alt="lessonSummaryIcon" />
                <div className="name">
                  {!_.get(slideData, "homework.content", false) && (
                    <span className="status">[in progress] </span>
                  )}
                  Homework
                </div>
              </div>
            )}
            {!(
              _.get(slideData, "lessenDetails.id", false) < 922 &&
              !_.get(slideData, "keyWords.content", false)
            ) && (
              <div
                className="resource-item default-item"
                onClick={() => {
                  if (!_.get(slideData, "keyWords.content", false)) {
                    setOpenBuildingInProgressDialog(true);
                    setBuildingInProgressDialogText(
                      "Your lesson keywords are being generated, please come back in a few minutes."
                    );
                  } else
                    window.open(
                      Paths.Keywords.replace(":jobid", lessenId),
                      "_blank"
                    );
                }}
              >
                <img src={lessonSummaryIcon} alt="lessonSummaryIcon" />
                <div className="name">
                  {!_.get(slideData, "keyWords.content", false) && (
                    <span className="status">[in progress] </span>
                  )}
                  Keywords
                </div>
              </div>
            )}

            {!(
              _.get(slideData, "lessenDetails.id", false) < 922 &&
              !_.get(slideData, "summary.content", false)
            ) && (
              <div
                className="resource-item default-item"
                onClick={() => {
                  if (!_.get(slideData, "summary.content", false)) {
                    setOpenBuildingInProgressDialog(true);
                    setBuildingInProgressDialogText(
                      "Your lesson summary are being generated, please come back in a few minutes."
                    );
                  } else
                    window.open(
                      Paths.LessonSummary.replace(":jobid", lessenId),
                      "_blank"
                    );
                }}
              >
                <img src={lessonSummaryIcon} alt="lessonSummaryIcon" />
                <div className="name">
                  {!_.get(slideData, "summary.content", false) && (
                    <span className="status">[in progress] </span>
                  )}
                  Summary
                </div>
              </div>
            )}
            <div
              className="resource-item default-item"
              onClick={() => {
                if (
                  !_.get(
                    slideData,
                    "lessenDetails.studyMaterialGenerated",
                    false
                  )
                ) {
                  setOpenBuildingInProgressDialog(true);
                  setBuildingInProgressDialogText(
                    "Your study guide are being generated, please come back in a few minutes."
                  );
                } else
                  window.open(
                    Paths.StudyMaterial.replace(":jobid", lessenId),
                    "_blank"
                  );
              }}
            >
              <img src={lessonSummaryIcon} alt="lessonSummaryIcon" />
              <div className="name">
                {!_.get(
                  slideData,
                  "lessenDetails.studyMaterialGenerated",
                  false
                ) && <span className="status">[in progress] </span>}
                Study Guide
              </div>
            </div>
            {/* {resources.length === 0 &&
              !_.get(
                slideData,
                "lessenDetails.studyMaterialGenerated",
                false
              ) && <div className="no-resource">No resources found</div>} */}
          </>
        )}
      </div>
      <div
        className="btn blue-btn btn-create-resources"
        onClick={() => setView("create-view")}
      >
        Create resources from youtube video
      </div>
    </div>
  );

  const createView = (
    <div className="wrapper">
      <div className="back" onClick={() => setView("resource-view")}>
        <img src={leftBlueIcon} alt="leftBlueIcon" />
        <span>Back to Resources</span>
      </div>
      <div className="title create-worksheets-title">
        Create worksheets from any link
      </div>
      <div className="create-worksheet-input-wrapper">
        <input
          type="text"
          className="create-worksheet-input"
          placeholder="Paste a URL of a YouTube video, or an article, and we’ll generate a worksheet from it. "
          value={inputLink}
          onChange={(e) => setInputLink(e.target.value)}
        />
      </div>
      {loading && <Loading className="resource-loading no-m-t" noText={true} />}
      {!loading && (
        <div
          className="btn blue-btn btn-create-resources"
          onClick={async () => {
            setLoading(true);
            try {
              if (inputLink.length === 0)
                toast.error("Please Paste a URL of a YouTube video.", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              else {
                let res = await generateResource(inputLink, lessenId);
                if (res && res.data && res.data.error) {
                  toast.error("Please Enter Valid Youtube URL.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                } else {
                  toast.success(
                    "Your worksheet is being generated, it can take up to 1-2 minutes",
                    {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    }
                  );
                  setView("resource-view");
                  setInputLink("");
                }
              }
              setLoading(false);
            } catch (e) {
              console.log(e);
              setLoading(false);
            }
          }}
        >
          Create Worksheet
        </div>
      )}
      <div className="flex-1"></div>
    </div>
  );

  return (
    <>
      <span onClick={handleClickOpen}>{children}</span>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              borderRadius: "32px",
              overflowY: "unset",
              margin: "100px",
              minHeight: "80%",
              maxHeight: "88%",
            },
          }}
        >
          <DialogContent sx={{ borderRadius: "32px", display: "flex" }}>
            <DialogTitle sx={{ padding: 0 }}>
              <img
                src={dialogCloseIcon}
                alt="dialogCloseIcon"
                className="dialogCloseIcon"
                style={{
                  position: "absolute",
                  top: "-46px",
                  right: "-74px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            </DialogTitle>
            <DialogContentText
              sx={{ flex: "1 1", display: "flex" }}
              id="alert-dialog-slide-description"
            >
              <div className="resources-view-dialog">
                {view === "resource-view" ? resourceView : createView}
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <BuildingInProgressDialog
          open={openBuildingInProgressDialog}
          setOpen={setOpenBuildingInProgressDialog}
          text={buildingInProgressDialogText}
        />
      </div>
    </>
  );
};

export default ResourcesViewDialog;
