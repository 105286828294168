import Presentation from "component/Presentation";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLesson } from "services/AI";
import _ from "lodash";

let fetchInterval = null;

const sectionBgColors = [
  "rgba(211, 211, 211, 0.5)",
  "rgba(173, 216, 230, 0.5)",
  "rgba(152, 251, 152, 0.5)",
  "rgba(255, 215, 0, 0.5)",
  "rgba(255, 255, 153, 0.5)",
  "rgba(230, 230, 250, 0.5)",
  "rgba(255, 182, 193, 0.5)",
  "rgba(230, 230, 250, 0.5)",
  "rgba(175, 238, 238, 0.5)",
  "rgba(152, 255, 152, 0.5)",
  "rgba(255, 160, 122, 0.5)",
  "rgba(240, 230, 140, 0.5)",
  "rgba(135, 206, 250, 0.5)",
  "rgba(224, 224, 255, 0.5)",
];
export const assignColorsToSections = (slides) => {
  let outPut = _.cloneDeep(slides);
  for (let i = 0; i < outPut.length; i++) {
    const sections = outPut[i].sections;
    let numOfTitle = 0;
    if (sections)
      for (let s = 0; s < sections.length; s++) {
        if (sections[s].type === "title") {
          numOfTitle += 1;
        } else if (!sections[s].bgColor)
          outPut[i].sections[s].bgColor =
            sectionBgColors[numOfTitle % sectionBgColors.length];
      }
  }
  return outPut;
};

function PresentationWrapper() {
  let { jobid, mode } = useParams();
  const [slideData, setSlideData] = useState();

  const getSlideData = () => {
    getLesson(jobid).then((res) => {
      setSlideData(res.data);
      if (_.get(res, "data.status", "") === "done") {
        if (fetchInterval) {
          clearInterval(fetchInterval);
        }
      }
    });
  };
  useEffect(() => {
    getSlideData();
    fetchInterval = setInterval(() => {
      getSlideData();
    }, 5000); // 5sec
    return () => {
      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
    };
  }, [mode]);

  return (
    <>
      <Presentation
        status={_.get(slideData, "status", "wait-unknown")}
        data={
          _.get(slideData, "slides", []).length > 0
            ? []
            : _.get(slideData, "lesson", [])
        }
        slidesData={_.get(slideData, "slides", [])}
        jobid={jobid}
        sSlideId={_.get(slideData, "slideId", 1)}
        sSectionId={_.get(slideData, "sectionId", 1)}
        mode={mode}
        getSlideData={getSlideData}
        // key={_.get(slideData, "slides", []).length}
      />
    </>
  );
}

export default PresentationWrapper;
