import React from "react";
import "./style.scss";
import { whitePlusIcon } from "assets";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import AuthContext from "contexts/authContext";
import _ from "lodash";

function WelcomeAboardCard() {
  const navigate = useNavigate();
  const { auth } = React.useContext(AuthContext);

  return (
    <div className="welcome-aboard-card">
      <div className="title">
        Welcome Aboard, {_.get(auth, "userData.FirstName", "")}!
      </div>
      <div className="sub-title">
        Create your first AI generated Lesson by clicking below
      </div>
      <div
        className="create-btn blue-btn"
        onClick={() => navigate(Paths.CreateLesson)}
      >
        <img src={whitePlusIcon} alt="whitePlusIcon" />
        <span>Create Lesson</span>
      </div>
    </div>
  );
}

export default WelcomeAboardCard;
