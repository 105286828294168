import React, { useState, useEffect } from "react";
import "./style.scss";

const BlinkingDotComponent = ({ text = "In Progress" }) => {
  const [isBlinking, setIsBlinking] = useState(true);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setIsBlinking((prevIsBlinking) => !prevIsBlinking);
    }, 500); // Blinking interval in milliseconds

    return () => {
      clearInterval(blinkInterval);
    };
  }, []);

  return (
    <div className="blink-wrapper">
      <div
        className="blink-dot"
        style={{
          backgroundColor: isBlinking ? "red" : "transparent",
        }}
      />
      {isBlinking ? text : ""}
    </div>
  );
};

export default BlinkingDotComponent;
