export const subjects = [
  { id: 1, name: "Maths", code: "Maths" },
  { id: 11, name: "Further Maths", code: "Further-Maths" },
  { id: 2, name: "Biology", code: "Biology" },
  { id: 3, name: "Chemistry", code: "Chemistry" },
  { id: 4, name: "Physics", code: "Physics" },
  { id: 5, name: "Economics", code: "Economics" },
  { id: 6, name: "History", code: "History" },
  { id: 7, name: "Religious education", code: "Religious-Studies" },
  { id: 8, name: "Psychology", code: "Psychology" },
  { id: 9, name: "Sociology", code: "Sociology" },
  { id: 10, name: "Business Studies", code: "Business-Studies" },
  { id: 12, name: "Geography", code: "Geography" },
];
