import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SignUpDone() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
      navigate({
        pathname: "/",
        state: { isRedirectFromRegister: true },
      });
    }, 2500);
  }, []);
  return (
    <div
      style={{
        textAlign: "center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <lottie-player
        src="https://assets1.lottiefiles.com/packages/lf20_y2hxPc.json"
        background="transparent"
        speed="1"
        style={{ width: "300px", height: "300px" }}
        autoplay
      ></lottie-player>
      <h1>You're set.</h1>
      <h4>Redirecting...</h4>
    </div>
  );
}

export default SignUpDone;
