import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import "./style.scss";
import { dialogCloseIcon } from "assets";
import _ from "lodash";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InputDialog = (props) => {
  const { open, setOpen, name, onAdd } = props;

  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
  }, [open]);

  useEffect(() => {
    if (open) {
      if (!window.history.state?.popupOpen)
        window.history.pushState({ popupOpen: true }, "");
    } else {
      if (window.history.state?.popupOpen) {
        window.history.back();
      }
    }
    const handleBackButton = (e) => {
      if (open) {
        handleClose();
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    };
    window.addEventListener("popstate", handleBackButton);
    return () => window.removeEventListener("popstate", handleBackButton);
  }, [open]);

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (setOpen) setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { borderRadius: "32px", overflowY: "unset", maxWidth: "660px" },
        }}
      >
        <DialogContent sx={{ borderRadius: "32px" }}>
          <DialogTitle sx={{ padding: 0 }}>
            <img
              src={dialogCloseIcon}
              alt="dialogCloseIcon"
              className="dialogCloseIcon"
              style={{
                position: "absolute",
                top: "-46px",
                right: "-74px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="input-dialog">
              <label>Enter {name}</label>
              <input
                type="text"
                placeholder={`Enter ${_.toLower(name)}`}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <div className="btn-wrapper">
                <div
                  className="btn blue-btn btn-v-big"
                  onClick={() => {
                    if (onAdd && value.length > 0) onAdd(value);
                  }}
                >
                  Add {name}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InputDialog;
