import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import "./style.scss";
import { dialogCloseIcon } from "assets";
import { uploadImage } from "services/AI";
import LoadingDots from "component/LoadingDots";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageUploadDialog = (props) => {
  const { open, setOpen, onAddClick } = props;

  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setImage(null);
    setImageUrl("");
    setImageFile(null);
    setLoading(false);
  }, [open]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onAddClickHandle = async () => {
    setLoading(true);
    if (imageUrl) {
      onAddClick(imageUrl);
      handleClose();
    } else if (imageFile) {
      const imageUrl = await uploadImage(imageFile.type, imageFile);
      onAddClick(imageUrl);
      handleClose();
    }
    setLoading(false);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (setOpen) setOpen(false);
  };

  // useEffect(() => {
  //   if (open) {
  //     if (!window.history.state?.popupOpen)
  //       window.history.pushState({ popupOpen: true }, "");
  //   } else {
  //     if (window.history.state?.popupOpen) {
  //       window.history.back();
  //     }
  //   }
  //   const handleBackButton = (e) => {
  //     if (open) {
  //       handleClose();
  //       if (e) {
  //         e.stopPropagation();
  //         e.preventDefault();
  //       }
  //     }
  //   };
  //   window.addEventListener("popstate", handleBackButton);
  //   return () => window.removeEventListener("popstate", handleBackButton);
  // }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { borderRadius: "32px", overflowY: "unset", maxWidth: "660px" },
        }}
      >
        <DialogContent sx={{ borderRadius: "32px" }}>
          <DialogTitle sx={{ padding: 0 }}>
            <img
              src={dialogCloseIcon}
              alt="dialogCloseIcon"
              className="dialogCloseIcon"
              style={{
                position: "absolute",
                top: "-46px",
                right: "-74px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="image-upload-dialog">
              <label>Add Image</label>
              {/* <input
                type="text"
                placeholder="Image URL (Optional)"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              /> */}
              {image ? (
                <img src={image} alt="Uploaded Preview" />
              ) : (
                <div className="image-dropzone">
                  <input
                    type="file"
                    onChange={handleImageChange}
                    id="imageInput"
                    hidden
                  />
                  <label htmlFor="imageInput">
                    Drag and Drop or Click here to add image
                  </label>
                </div>
              )}
              {loading ? (
                <div className="loading-wrapper">
                  <LoadingDots />
                </div>
              ) : (
                <div className="btn-wrapper">
                  <div
                    className={`btn blue-btn btn-v-big ${
                      !imageUrl && !imageFile && "disabled"
                    }`}
                    onClick={onAddClickHandle}
                  >
                    Add Image
                  </div>
                </div>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageUploadDialog;
