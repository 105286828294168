import TopSectionImg from "assets/images/landing/top-section.png";
import ManWithLaptop from "assets/images/landing/man-with-laptop.png";
import CatWithBooks from "assets/images/landing/cat-with-books.png";
import FlowBooks from "assets/images/landing/flow-books.png";
import ExperienceImage from "assets/images/landing/experience.png";
import CommunityImage from "assets/images/landing/community.png";
import "./style.scss";
import Header from "component/Header";
import Footer from "component/Landing/Footer/Footer";
import Plan from "component/Landing/Plan/Plan";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import { useEffect } from "react";
import { contactUsPageImage, loginImage, signUpImage } from "assets";

function Landing() {
  const navigate = useNavigate();
  useEffect(() => {
    const img1 = new Image();
    img1.src = loginImage;
    const img2 = new Image();
    img2.src = signUpImage;
    const img3 = new Image();
    img3.src = contactUsPageImage;
  }, []);
  return (
    <div className="wrapper landing">
      <Header></Header>

      <section className="top-section">
        <div className="container">
          <div className="top-section__wrapper">
            <div className="top-section__caption">
              <h1>
                Prepare Lessons with <span>Lessen AI</span>
              </h1>
              <p>
                Are you tired of spending hours crafting engaging presentations
                for your lessons? Look no further - Lessen AI is here to
                simplify and accelerate your lesson preparation process.
              </p>
              <a
                href="#"
                onClick={() => navigate(Paths.SignUp)}
                className="btn btn-filled"
              >
                Start Now
              </a>
            </div>
            <div className="top-section__image">
              <picture>
                <img src={TopSectionImg} alt="" />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section className="how-works container" id="features">
        <h2>How it Works</h2>
        <div className="how-works__list use-flow">
          <div className="use-flow__item wave">
            <picture>
              <img src={ManWithLaptop} alt="man-with-laptop" />
            </picture>
            <div className="use-flow__caption">
              <div className="use-flow__icon">
                <svg
                  width="26"
                  height="39"
                  viewBox="0 0 26 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.34 13.988C1.85467 14.3693 1.33467 14.4213 0.78 14.144C0.26 13.8667 0 13.4333 0 12.844V10.764C0 8.82267 0.78 7.24533 2.34 6.032L7.904 1.768C9.464 0.589333 11.232 0 13.208 0H14.768C15.5653 0 16.2413 0.294666 16.796 0.883998C17.3853 1.47333 17.68 2.16667 17.68 2.964V34.996C17.68 35.7933 17.3853 36.4867 16.796 37.076C16.2413 37.6653 15.5653 37.96 14.768 37.96H13.208C12.4107 37.96 11.7173 37.6653 11.128 37.076C10.5387 36.4867 10.244 35.7933 10.244 34.996V8.008L10.192 7.956L10.14 8.008L2.34 13.988Z"
                    fill="#4285F4"
                  />
                </svg>
              </div>

              <p>Enter Subject, Title, Study & Keywords</p>
            </div>
          </div>
          <div className="use-flow__item wave">
            <picture>
              <img src={CatWithBooks} alt="man-with-laptop" />
            </picture>
            <div className="use-flow__caption">
              <div className="use-flow__icon">
                <svg
                  width="26"
                  height="41"
                  viewBox="0 0 26 41"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.856 11.076C25.856 14.3347 24.816 17.472 22.736 20.488C20.6907 23.4693 16.704 27.4387 10.776 32.396C10.7067 32.4653 10.672 32.5173 10.672 32.552C10.672 32.5867 10.6893 32.604 10.724 32.604H22.996C23.7933 32.604 23.4867 32.8987 24.076 33.488C24.6653 34.0427 24.96 34.7187 24.96 35.516C24.96 36.3133 24.6653 37.0067 24.076 37.596C23.4867 38.1853 22.7933 38.48 21.996 38.48H2.912C2.11467 38.48 1.42133 38.1853 0.832 37.596C0.277333 37.0067 0 36.3133 0 35.516C0 33.54 1.74533 31.9627 3.236 30.784C9.23333 26 13.2547 22.2733 15.3 19.604C17.3453 16.9347 18.368 14.3347 18.368 11.804C18.368 7.956 16.3053 6.032 12.18 6.032C10.0653 6.032 7.5 6.62133 4.484 7.8C3.82533 8.07733 3.20133 8.02533 2.612 7.644C2.05733 7.26267 1.78 6.74267 1.78 6.084V5.2C1.78 4.29867 2.02267 3.484 2.508 2.756C3.028 1.99333 3.704 1.49067 4.536 1.248C7.48267 0.416 10.3773 0 13.22 0C17.276 0 20.396 0.970667 22.58 2.912C24.764 4.85333 25.856 7.57467 25.856 11.076Z"
                    fill="#4285F4"
                  />
                </svg>
              </div>

              <p>Sit Back & Relax as our AI does Magic</p>
            </div>
          </div>
          <div className="use-flow__item ">
            <picture>
              <img src={FlowBooks} alt="man-with-laptop" />
            </picture>
            <div className="use-flow__caption">
              <div className="use-flow__icon">
                <svg
                  width="26"
                  height="40"
                  viewBox="0 0 26 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.964 6.63601C2.16667 6.63601 1.47333 6.35868 0.884 5.80401C0.294667 5.21468 0 4.52134 0 3.72401C0 2.92668 0.294667 2.23334 0.884 1.64401C1.47333 1.05468 2.16667 0.76001 2.964 0.76001H21.424C22.2213 0.76001 22.8973 1.05468 23.452 1.64401C24.0413 2.23334 24.336 2.92668 24.336 3.72401C24.336 5.70001 23.608 7.32934 22.152 8.61201L13.728 16.256C13.6933 16.2907 13.676 16.3253 13.676 16.36C13.676 16.3947 13.6933 16.412 13.728 16.412H14.56C17.7493 16.412 20.2627 17.3307 22.1 19.168C23.9373 20.9707 24.856 23.5013 24.856 26.76C24.856 30.7467 23.6427 33.832 21.216 36.016C18.7893 38.1653 15.3227 39.24 10.816 39.24C8.14667 39.24 5.564 38.928 3.068 38.304C2.236 38.096 1.56 37.628 1.04 36.9C0.52 36.172 0.26 35.3573 0.26 34.456V33.884C0.26 33.1907 0.537333 32.6533 1.092 32.272C1.68133 31.856 2.32267 31.752 3.016 31.96C5.824 32.896 8.32 33.364 10.504 33.364C12.792 33.364 14.56 32.8093 15.808 31.7C17.056 30.5907 17.68 28.9787 17.68 26.864C17.68 24.9227 16.9693 23.536 15.548 22.704C14.1613 21.8373 11.7 21.404 8.164 21.404H7.592C6.89867 21.404 6.292 21.144 5.772 20.624C5.28667 20.104 5.044 19.4973 5.044 18.804C5.044 17.0707 5.668 15.632 6.916 14.488L15.392 6.79201C15.4267 6.75734 15.444 6.72268 15.444 6.68801C15.444 6.65334 15.4267 6.63601 15.392 6.63601H2.964Z"
                    fill="#4285F4"
                  />
                </svg>
              </div>

              <p>Modify and Add your Personal Touch</p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="integration container-fluid">
        <div className="integration__image">
          <picture>
            <img src={IntegrationImage} alt="" />
          </picture>
        </div>
        <div className="integration__text">
          <div className="integration__badges">
            <Badge text="Gmail">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.54547 22.004V14.2767L7.14898 12.0843L5 10.8676V20.6404C5 21.3949 5.61133 22.004 6.36367 22.004H9.54547Z"
                  fill="#4285F4"
                />
                <path
                  d="M20.4545 22.004H23.6363C24.3909 22.004 25 21.3926 25 20.6404V10.8677L22.5659 12.2612L20.4545 14.2767V22.004Z"
                  fill="#34A853"
                />
                <path
                  d="M9.54547 14.2767L9.21938 11.2574L9.54547 8.36762L15 12.4586L20.4545 8.36762L20.8193 11.1014L20.4545 14.2767L15 18.3676L9.54547 14.2767Z"
                  fill="#EA4335"
                />
                <path
                  d="M20.4545 8.36762V14.2767L25 10.8677V9.04942C25 7.36309 23.075 6.40176 21.7273 7.41309L20.4545 8.36762Z"
                  fill="#FBBC04"
                />
                <path
                  d="M5 10.8676L9.54547 14.2767V8.36762L8.27266 7.4131C6.92266 6.4017 5 7.3631 5 9.04935V10.8676Z"
                  fill="#C5221F"
                />
              </svg>
            </Badge>
            <Badge text="Outlook">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5125 9.28646V13.2407L18.8957 14.1107C18.9457 14.1216 18.9974 14.1216 19.0474 14.1107L24.9949 10.1008C24.9906 9.90623 24.9194 9.71905 24.7933 9.57074C24.6672 9.42244 24.494 9.32205 24.3026 9.28646H17.5125Z"
                  fill="#0072C6"
                />
                <path
                  d="M17.5125 14.7157L18.7737 15.582C18.8328 15.618 18.9006 15.637 18.9698 15.637C19.0389 15.637 19.1067 15.618 19.1658 15.582C18.9492 15.7127 24.9942 11.6993 24.9942 11.6993V18.9666C25.01 19.1139 24.9934 19.2628 24.9456 19.403C24.8978 19.5432 24.8199 19.6712 24.7174 19.7781C24.615 19.8851 24.4904 19.9683 24.3524 20.0221C24.2144 20.0758 24.0663 20.0988 23.9185 20.0893H17.5117L17.5125 14.7157ZM10.983 12.8927C10.7734 12.8917 10.5675 12.9474 10.387 13.0539C10.2065 13.1604 10.0582 13.3138 9.95777 13.4977C9.6835 13.9853 9.5519 14.5402 9.57801 15.099C9.54951 15.6567 9.68128 16.2109 9.95777 16.696C10.0578 16.8724 10.2024 17.0193 10.3772 17.1221C10.552 17.2249 10.7507 17.2798 10.9535 17.2814C11.1562 17.283 11.3558 17.2312 11.5322 17.1313C11.7086 17.0313 11.8556 16.8867 11.9583 16.7119C12.2318 16.229 12.3609 15.6777 12.3302 15.1236C12.3615 14.5521 12.2367 13.9828 11.9692 13.4768C11.8736 13.2988 11.7312 13.1504 11.5574 13.0474C11.3836 12.9445 11.185 12.891 10.983 12.8927Z"
                  fill="#0072C6"
                />
                <path
                  d="M5 7.27794V22.7476L16.7681 25.2153V5.00012L5 7.27794ZM12.8752 17.6281C12.6551 17.9384 12.3624 18.1902 12.0226 18.3615C11.6828 18.5328 11.3063 18.6183 10.9259 18.6107C10.5551 18.6173 10.1881 18.5342 9.85629 18.3685C9.52445 18.2029 9.23752 17.9595 9.01993 17.6591C8.50385 16.9386 8.24569 16.0652 8.28713 15.1799C8.24323 14.2512 8.50606 13.3338 9.03509 12.5693C9.25801 12.253 9.55532 11.9964 9.9008 11.8222C10.2463 11.6479 10.6293 11.5613 11.0162 11.57C11.3843 11.5627 11.7487 11.6458 12.0772 11.812C12.4058 11.9783 12.6885 12.2227 12.9005 12.5238C13.4114 13.2599 13.6652 14.1441 13.6225 15.0391C13.6675 15.9611 13.4046 16.8719 12.8752 17.6281Z"
                  fill="#0072C6"
                />
              </svg>
            </Badge>
          </div>
          <h2>Seamless Integration and Accessibility</h2>
          <p>
            Lessen AI will seamlessly synchronize with your existing school
            calendar through Google or Outlook. Generated presentations are
            compatible with various presentation software platforms, including
            PowerPoint, Google Slides, and more.{" "}
          </p>
        </div>
      </section> */}
      <section className="experience container-fluid">
        <div className="experience__text">
          <h2>
            Experience the Future of Teaching with <span>Lessen AI</span>{" "}
            Streamline your Process
          </h2>
          <p>
            Preparing lessons has never been easier. With Lessen AI, all you
            need to do is provide your presentation title, subject, and
            keywords. Our powerful algorithm then works its magic, generating a
            fully functional and visually appealing presentation tailored
            specifically to your needs.
          </p>
          <a
            href="#"
            onClick={() => navigate(Paths.SignUp)}
            className="btn btn-medium"
          >
            Create Lesson
          </a>
        </div>
        <div className="experience__image">
          <picture>
            <img src={ExperienceImage} alt="" />
          </picture>
        </div>
      </section>
      <section className="pricing container">
        <h2 id="pricing">Pricing Plans</h2>
        <div className="pricing__plans">
          <Plan
            headline="Starter Plan"
            description="Best if you are curious teacher and want to try Lessen AI for £1 and see if it is for you."
            price="£1"
            benefits={[
              "Unlimited Presentations",
              "Lessons in line with Ofsted’s “outstanding” framework",
              "AI Generated images to suit the topic of your slides",
              "Claim back more than 10 hours per week of your time!",
              "Cancel Anytime",
            ]}
            btnText="Start >"
            btnLink="#"
            onClick={() => navigate(Paths.Login)}
          ></Plan>
          <Plan
            headline="Monthly Plan"
            description="Best if you want to use Lessen AI to create professional presentations on a regular basis."
            price="£39/month"
            benefits={[
              "Unlimited Presentations",
              "Lessons in line with Ofsted’s “outstanding” framework",
              "AI Generated images to suit the topic of your slides",
              "Claim back more than 10 hours per week of your time!",
              "Cancel Anytime",
            ]}
            btnText="Select >"
            btnLink="#"
            onClick={() => navigate(Paths.Login)}
          ></Plan>
          <Plan
            headline="Annual Plan"
            description="Best if you want to use Lessen AI to create professional presentations on a regular basis, and save £48 a year"
            price="£35/month"
            benefits={[
              "Unlimited Presentations",
              "Lessons in line with Ofsted’s “outstanding” framework",
              "AI Generated images to suit the topic of your slides",
              "Claim back more than 10 hours per week of your time!",
              "Cancel Anytime",
            ]}
            btnText="Start >"
            btnLink="#"
            onClick={() => navigate(Paths.Login)}
            isSelected={true}
          ></Plan>
        </div>
      </section>
      {/* <section className="faq container" id="faq">
        <Accordion className="faq__list"></Accordion>
      </section> */}
      <section className="community">
        <div className="container">
          <div className="community__wrapper">
            <div className="community__text">
              <h2>Join the Growing Community </h2>
              <p>
                Join the growing community of educators who have discovered the
                power of Lessen AI and are transforming the way they teach. Your
                teaching journey will never be the same again.
              </p>
              <a
                href="#"
                onClick={() => navigate(Paths.SignUp)}
                className="btn"
              >
                Sign Up
              </a>
            </div>
            <div className="community__image">
              <picture>
                <img src={CommunityImage} alt="" />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Landing;
