import { circleDownArrow, circleUpArrow } from "assets";
import _ from "lodash";
import { useState } from "react";
import "./style.scss";
import ContentEditable from "component/ContentEditable";

const LessonSummeryItem = (props) => {
  const { si, i, titleEdit = false, onTitleChange } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="lesson-summery-item">
      <div className="title">
        <span>
          {i + 1})
          <ContentEditable
            isEditable={titleEdit}
            content={si.slideTitle}
            onContentChange={(newContent) => {
              if (onTitleChange) {
                onTitleChange(newContent);
              }
            }}
          ></ContentEditable>
        </span>
        {_.get(si, "contentTitles", []).length > 0 && (
          <img
            src={open ? circleUpArrow : circleDownArrow}
            alt="circleDownArrow"
            onClick={(event) => {
              event.stopPropagation();
              setOpen(!open);
            }}
          />
        )}
      </div>
      {open && (
        <ul className="points">
          {_.map(_.get(si, "contentTitles", []), (item) => (
            <li className="point">{item.replace(":", "")}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LessonSummeryItem;
