import { removeTitleIcon } from "assets";
import "./style.scss";
import React from "react";
import _ from "lodash";

const ContentEditable = React.forwardRef(
  (
    {
      content,
      onContentChange,
      isEditable = true,
      className = "",
      placeholder = "",
      wantPlaceholderImage,
      onFocus,
      onBlur,
    },
    ref
  ) => {
    const handleFocus = (e) => {
      if (onFocus) onFocus(e);
      if (
        e.target.innerHTML.indexOf(`<span class="placeholder">${placeholder}`) >
        -1
      ) {
        e.target.innerHTML = "";
      }
    };

    const handleBlur = (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (onBlur) {
        onBlur(e);
      }
      let htmlContent = e.target.innerHTML;
      if (
        htmlContent === "<br>" ||
        htmlContent === "&nbsp;" ||
        _.trim(htmlContent) === ""
      ) {
        htmlContent = "";
        e.target.innerHTML = `<span class="placeholder">${placeholder}${
          wantPlaceholderImage
            ? `<img src=${removeTitleIcon} alt="removeTitleIcon" />`
            : ""
        }</span>`;
      }
      onContentChange(htmlContent);
    };

    return (
      <>
        <div
          className={`ContentEditable ${className}`}
          contentEditable={isEditable}
          suppressContentEditableWarning
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleBlur}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={ref}
          dangerouslySetInnerHTML={{
            __html:
              content && _.trim(content).length > 0
                ? content
                : `<span class="placeholder">${placeholder}${
                    wantPlaceholderImage
                      ? `<img src=${removeTitleIcon} alt="removeTitleIcon" />`
                      : ""
                  }</span>`,
          }}
        />
      </>
    );
  }
);

export default ContentEditable;
