import * as Yup from "yup";
import { Formik } from "formik";
import { Box, FormHelperText } from "@mui/material";
import _ from "lodash";
import "./style.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updatePassword } from "services/Auth";
import Loading from "component/Loading";
import { resetPasswordImage, rightGoldenMark } from "assets";
import InfoDialog from "dialogs/InfoDialog";
import { Paths } from "routes";

const ChangePassword = () => {
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  return (
    <div className="change-password-wrapper">
      {loader && <Loading />}

      <div className={`change-password ${loader ? "ui-hide" : ""}`}>
        <div className="card-wrapper">
          <div className="logo">Lessen AI</div>
          <div className="card">
            <div className="left-section">
              <div className="title mb-12">Change Password</div>
              <div className="sub-title">
                Please enter your old password in order to create new password.
                If you don’t remember your current password click on “Forgot Old
                Password?” button.
              </div>
              <Formik
                initialValues={{
                  oldPassword: "",
                  password: "",
                  confirmpassword: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  oldPassword: Yup.string()
                    .min(6)
                    .max(255)
                    .required("Old Password is required"),
                  password: Yup.string()
                    .min(6)
                    .max(255)
                    .required("New Password is required"),
                  confirmpassword: Yup.string()
                    .oneOf([Yup.ref("password"), null], "Passwords must match")
                    .required("Confirm Password is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting, setFieldValue }
                ) => {
                  try {
                    setLoader(true);
                    if (updatePassword) {
                      const resData = await updatePassword({
                        OldPassword: values.oldPassword,
                        NewPassword: values.password,
                      });

                      if (
                        resData &&
                        resData.data &&
                        resData.data.result === "Password changed"
                      ) {
                        setInfoDialogOpen(true);
                      }
                    }
                  } catch (err) {
                    console.error(err);
                    setStatus({ success: false });
                    setErrors({
                      submit: _.get(
                        err,
                        "response.data.title",
                        "Something went wrong"
                      ),
                    });
                    setSubmitting(false);
                  } finally {
                    setLoader(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="mt-20">
                      <input
                        type="password"
                        placeholder="Old Password"
                        className="ui-input"
                        name="oldPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.oldPassword}
                      />
                      {Boolean(touched.oldPassword && errors.oldPassword) && (
                        <FormHelperText error>
                          {touched.oldPassword && errors.oldPassword}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mt-20">
                      <input
                        type="password"
                        placeholder="New Password"
                        className="ui-input"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                      />
                      {Boolean(touched.password && errors.password) && (
                        <FormHelperText error>
                          {touched.password && errors.password}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="mt-20 ">
                      <input
                        type="password"
                        placeholder="Confirm New Password"
                        className="ui-input"
                        name="confirmpassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmpassword}
                      />
                      {Boolean(
                        touched.confirmpassword && errors.confirmpassword
                      ) && (
                        <FormHelperText error>
                          {touched.confirmpassword && errors.confirmpassword}
                        </FormHelperText>
                      )}
                    </div>

                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error className="text-center">
                          {errors.submit}
                        </FormHelperText>
                      </Box>
                    )}

                    <Box mt={5}>
                      <button
                        className="btn-login hover-zoom"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Reset Password
                      </button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
            <div className="right-section">
              <img src={resetPasswordImage} alt="signUpImage" />
            </div>
          </div>
        </div>
      </div>
      <InfoDialog
        open={infoDialogOpen}
        setOpen={(newValue) => {
          if (!newValue) navigate(Paths.Login);
          setInfoDialogOpen(newValue);
        }}
        text="Password Changed Successfully!"
        btnText="Okay"
        image={rightGoldenMark}
      />
    </div>
  );
};

export default ChangePassword;
