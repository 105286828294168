import { Navigate, createBrowserRouter } from "react-router-dom";
import SideMenu from "component/SideMenu";
import Home from "pages/Home";
import CreateLesson from "pages/CreateLesson";
import Loading from "component/Loading";
import Loading2 from "component/Loading2";
import PresentationWrapper from "component/PresentationWrapper";
import Login from "pages/Login";
import SignUp from "pages/SignUp";
import SignUpDone from "pages/SignUpDone";
import AuthChecker from "component/AuthChecker";
import LogOut from "pages/LogOut";
import Lesson from "pages/Lesson";
import Landing from "pages/Landing";
import ForgotPassword from "pages/ForgotPassword";
import Subscription from "pages/Subscription";
import ContactUs from "pages/ContactUs";
import ChangePassword from "pages/ChangePassword";
import StudyMaterial from "pages/StudyMaterial";
import StudyWorksheet from "pages/StudyWorksheet";
import LessonKeywords from "pages/LessonKeywords";

import LessonSummary from "pages/LessonSummary";
import LessonHomework from "pages/LessonHomework";

export const Paths = {
  Login: "/login",
  SignUp: "/sign-up",
  ContactUs: "/contact-us",
  LogOut: "log-out",
  SignUpDone: "/sign-up-done",
  ForgotPassword: "/forgot-password",
  ChangePassword: "/forgot-password/:token",
  Home: "/",
  Dashboard: "/dashboard",
  UpdatePassword: "/change-password",
  CreateLesson: "/create-lesson",
  Presentation: "/presentation/:jobid/:mode",
  StudyMaterial: "/study-material/:jobid",
  StudyWorksheet: "/study-worksheet/:jobid",
  Keywords: "/keywords/:jobid",
  Homework: "/homework/:jobid",
  LessonSummary: "/lesson-summary/:jobid",
  Lesson: "/lesson/:jobid",
  Subscription: "/subscription",
};

export const router = (auth = {}) => {
  let routes = [
    {
      path: Paths.Presentation,
      element: <PresentationWrapper />,
    },
    {
      path: Paths.StudyMaterial,
      element: <StudyMaterial />,
    },
    {
      path: Paths.StudyWorksheet,
      element: <StudyWorksheet />,
    },
    {
      path: Paths.LessonSummary,
      element: <LessonSummary />,
    },
    {
      path: Paths.Keywords,
      element: <LessonKeywords />,
    },
    {
      path: Paths.Homework,
      element: <LessonHomework />,
    },
  ];
  const isLoggedIn = localStorage.getItem("token");
  if (isLoggedIn) {
    routes.push({
      path: "/",
      element: (
        // <AuthChecker mustAuth>
        <SideMenu />
        // </AuthChecker>
      ),
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: Paths.CreateLesson,
          element: <CreateLesson />,
        },
        {
          path: Paths.UpdatePassword,
          element: <ChangePassword />,
        },
        {
          path: Paths.Lesson,
          element: <Lesson />,
        },
        {
          path: Paths.Subscription,
          element: <Subscription />,
        },
        {
          path: "/loading",
          element: <Loading />,
        },
        {
          path: "/loading-1",
          element: (
            <>
              Note:- on refresh icons will change <Loading2 />
            </>
          ),
        },
        {
          path: Paths.SignUpDone,
          element: <SignUpDone />,
        },
        {
          path: Paths.LogOut,
          element: <LogOut />,
        },
        {
          path: "*",
          element: <Navigate to={"/"} />,
        },
      ],
    });
  } else {
    routes.push({
      path: "/",
      element: <AuthChecker mustNonAuth onlyOutlet></AuthChecker>,
      children: [
        {
          path: "/",
          element: <Landing />,
        },
        {
          path: Paths.Login,
          element: <Login />,
        },
        {
          path: Paths.SignUp,
          element: <SignUp />,
        },
        {
          path: Paths.ContactUs,
          element: <ContactUs />,
        },
        {
          path: Paths.SignUpDone,
          element: <SignUpDone />,
        },
        {
          path: Paths.ForgotPassword,
          element: <ForgotPassword />,
        },
        {
          path: "/",
          element: <Navigate to={Paths.Login} />,
        },
        {
          path: "*",
          element: <Navigate to={Paths.Login} />,
        },
      ],
    });
  }
  return createBrowserRouter(routes);
};
