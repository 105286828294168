import React from "react";
import "./style.scss";
import Header from "component/Header";
import Footer from "component/Footer";
import { contactUsPageImage, contactUsPageMobileImage } from "assets";

function ContactUs() {
  return (
    <div className="contact-us-wrapper">
      <Header />
      <div className="contact-us">
        <div className="card-wrapper">
          <div className="card">
            <div className="left-section">
              <div className="title">Contact us</div>
              <div className="sub-title">
                For other queries, please email us at:
              </div>
              <div className="email">support@lessen.ai</div>
              <div
                className="send-mail"
                onClick={() => {
                  window.location.href = `mailto:support@lessen.ai?subject=Customer Support`;
                }}
              >
                Send us Email
              </div>
            </div>
            <div className="right-section">
              <img
                src={contactUsPageImage}
                alt="contactUsPageImage"
                className="desk-image"
              />
              <img
                src={contactUsPageMobileImage}
                alt="contactUsPageMobileImage"
                className="mobile-image"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
