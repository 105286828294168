import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import "./App.css";
import "react-resizable/css/styles.css";
import { AuthProvider } from "contexts/authContext";
import RootContextWrapper from "contexts/rootContext";
import AppInit from "utils/AppInit";
import Router from "component/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const THEME = createTheme({
    typography: {
      fontFamily: `'Open Sans', sans-serif`,
    },
  });

  return (
    <div>
      <ThemeProvider theme={THEME}>
        <AuthProvider>
          <RootContextWrapper>
            <ToastContainer />
            <Router />
            <AppInit />
          </RootContextWrapper>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
