import React from "react";
import WelcomeAboardCard from "component/WelcomeAboardCard";
import "./style.scss";
import PageLayout from "component/PageLayout";
import RecentLessons from "component/RecentLessons";
import AuthContext from "contexts/authContext";
import _ from "lodash";

function Home() {
  const { auth } = React.useContext(AuthContext);
  const lessenCount = +_.get(auth, "userData.LessenCount", 0);

  return (
    <PageLayout
      title={"Lessen AI"}
      className={"home-page"}
      hasMaxWidth
      hasCreate={lessenCount > 0}
    >
      <div className="card-space">
        {lessenCount > 0 ? (
          <div className="recent-lessons-wrapper">
            <RecentLessons />
          </div>
        ) : (
          <div className="card-wrapper">
            <WelcomeAboardCard />
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default Home;
