import "utils/landingStyle.scss";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";

function Footer(props) {
  const { className } = props;
  const navigate = useNavigate();
  return (
    <div className={"landing-style " + className}>
      <footer>
        <div className="container">
          <div className="footer__top">
            <a href="/" className="logo">
              Lessen AI
            </a>
            <ul className="footer__navigation">
              <li>
                <a href="/#features" onClick={() => navigate("/#features")}>
                  Features
                </a>
              </li>
              <li>
                <a href="/#pricing" onClick={() => navigate("/#pricing")}>
                  Pricing
                </a>
              </li>
              <li>
                <a href="#" onClick={() => navigate(Paths.ContactUs)}>
                  Contact
                </a>
              </li>
              {/* <li>
                <a href="/#faq" onClick={() => navigate("/#faq")}>
                  FAQs
                </a>
              </li> */}
            </ul>
          </div>
          <div className="footer__basement">
            <div className="footer__copyrights">
              <p>© 2023. Lessen AI | All Rights Reserved.</p>
            </div>
            <ul className="footer__links">
              <li>
                <a
                  href="https://app.termly.io/document/privacy-policy/67de1ba7-ed4b-4bed-8a55-7326b901fc5a"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://app.termly.io/document/terms-of-service/cafa1dd8-d321-4cbb-a207-0153baf81b0f"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a
                  href="https://app.termly.io/document/cookie-policy/bd33973e-0982-4e22-bdb8-d11c4a098c17"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookie Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
