import React, { useContext, useState } from "react";
import "./style.scss";
import PageLayout from "component/PageLayout";
import { MenuItem, Select } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { subjects } from "data/subjects";
import { examBoards } from "data/examBoards";
import { gradeLevels, studyLevels } from "data/studyLevels";
import InputDialog from "dialogs/InputDialog";
import _ from "lodash";
import AuthContext from "contexts/authContext";

function CreateLessonPage1(props) {
  const {
    data = {
      lessonTitle: "",
      subject: "",
      studyLevel: "",
      examBoard: "",
      keyComponents: [],
    },
    changePage,
  } = props;

  const [inputDialogOpen, setInputDialogOpen] = useState(false);
  const [inputDialogName, setInputDialogName] = useState("");

  const { auth } = useContext(AuthContext);

  const initialValues = {
    ...data,
    submit: null,
  };
  const region = _.get(auth, "userData.Region", "uk");

  const validationSchema = Yup.object().shape({
    lessonTitle: Yup.string().max(255).required("Subject is required"),
    subject: Yup.string().max(255).required("Subject is required"),
    studyLevel: Yup.string().max(255).required("Study Level is required"),
    ...(region !== "us" ? { examBoard: Yup.string().max(255) } : {}),
    // keyComponents: Yup.array().min(1).required("Key Components is required"),
  });

  return (
    <PageLayout
      title={"Lessen AI"}
      className={"create-lesson back-blur"}
      hasRegion
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          changePage(values);
          setSubmitting(false);
        }}
        key={region}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit} className="question-wrapper">
            <div className="question" id="lesson-title">
              <div className="q-number">
                <span>1</span>
              </div>
              <input
                className={`q-input ${
                  touched.lessonTitle && errors.lessonTitle ? "error" : ""
                }`}
                placeholder={"Insert Lesson Title..."}
                name={"lessonTitle"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lessonTitle}
              />
            </div>
            <div className="question" id="subject">
              <div className="q-number">
                <span>2</span>
              </div>
              {region === "eu" ? (
                <input
                  className={`q-input ${
                    touched.subject && errors.subject ? "error" : ""
                  }`}
                  placeholder={"Insert Subject..."}
                  name={"subject"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.subject}
                />
              ) : (
                <Select
                  value={values.subject ? [values.subject] : ""}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    if (value === "other") {
                      setInputDialogOpen(true);
                      setInputDialogName("subject");
                    } else {
                      setFieldValue("subject", value);
                    }
                  }}
                  className={`q-select-input ${
                    touched.subject && errors.subject ? "error" : ""
                  }`}
                  displayEmpty
                  name="subject"
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "Select Subject";
                    }

                    return selected.join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {subjects.map((s) => (
                    <MenuItem key={s.id} value={s.name}>
                      {s.name}
                    </MenuItem>
                  ))}
                  <MenuItem key={"other-subject"} value="other">
                    Other
                  </MenuItem>
                </Select>
              )}
            </div>
            <div className="question" id="study-level">
              <div className="q-number">
                <span>3</span>
              </div>
              {region === "eu" ? (
                <input
                  className={`q-input ${
                    touched.studyLevel && errors.studyLevel ? "error" : ""
                  }`}
                  placeholder={"Insert Study Level..."}
                  name={"studyLevel"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.studyLevel}
                />
              ) : (
                <Select
                  value={values.studyLevel ? [values.studyLevel] : ""}
                  onBlur={handleBlur}
                  id="studyLevel"
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    if (value === "other") {
                      setInputDialogOpen(true);
                      setInputDialogName(
                        region === "us" ? "Grade Level" : "study Level"
                      );
                    } else {
                      setFieldValue("studyLevel", value);
                    }
                  }}
                  className={`q-select-input ${
                    touched.studyLevel && errors.studyLevel ? "error" : ""
                  }`}
                  displayEmpty
                  name="studyLevel"
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return region === "us"
                        ? "Select Grade Level"
                        : "Select Study Level";
                    }

                    return selected.join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {(region === "us" ? gradeLevels : studyLevels).map((s) => (
                    <MenuItem key={s.id} value={s.name}>
                      {s.name}
                    </MenuItem>
                  ))}
                  <MenuItem key={"other-Study-Level"} value="other">
                    Other
                  </MenuItem>
                </Select>
              )}
            </div>
            {region !== "us" && (
              <div className="question" id="exam-board">
                <div className="q-number">
                  <span>4</span>
                </div>
                {region === "eu" ? (
                  <input
                    className={`q-input ${
                      touched.examBoard && errors.examBoard ? "error" : ""
                    }`}
                    placeholder={"Insert Exam Board..."}
                    name={"examBoard"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.examBoard}
                  />
                ) : (
                  <Select
                    value={values.examBoard ? [values.examBoard] : ""}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      if (value === "other") {
                        setInputDialogOpen(true);
                        setInputDialogName("exam Board");
                      } else {
                        setFieldValue("examBoard", value);
                      }
                    }}
                    className={`q-select-input ${
                      touched.examBoard && errors.examBoard ? "error" : ""
                    }`}
                    displayEmpty
                    name="examBoard"
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return "Select Exam Board";
                      }

                      return selected.join(", ");
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {examBoards.map((s) => (
                      <MenuItem key={s.id} value={s.name}>
                        {s.name}
                      </MenuItem>
                    ))}
                    <MenuItem key={"other-Exam-Board"} value="other">
                      Other
                    </MenuItem>
                    <MenuItem key={"na-Exam-Board"} value="N/A">
                      N/A
                    </MenuItem>
                  </Select>
                )}
              </div>
            )}
            <InputDialog
              open={inputDialogOpen}
              setOpen={setInputDialogOpen}
              name={inputDialogName}
              onAdd={(value) => {
                setFieldValue(inputDialogName.replace(" ", ""), _.trim(value));
                setInputDialogOpen(false);
              }}
            />
            {/* <div className="question" id="key-components">
              <div className="q-number">
                <span>5</span>
              </div>
              <Select
                multiple
                value={values.keyComponents}
                onBlur={handleBlur}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setFieldValue(
                    "keyComponents",
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                className={`q-select-input ${
                  touched.keyComponents && errors.keyComponents ? "error" : ""
                }`}
                displayEmpty
                name="keyComponents"
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return "Select Key Components";
                  }

                  return selected.join(", ");
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {keyComponents.map((s) => (
                  <MenuItem key={s.id} value={s.name}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </div> */}
            <div className="one-more">
              <button className="blue-btn">One more thing..</button>
            </div>
          </form>
        )}
      </Formik>
    </PageLayout>
  );
}

export default CreateLessonPage1;
