import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { generatePdf, getResource } from "services/AI";
import _ from "lodash";
import Loading from "component/Loading";
import { saveAs } from "file-saver";
import HtmlDocx from "html-docx-js/dist/html-docx";
import { pdfIcon, wordIcon } from "assets";

export const pdfExport = async (fileName) => {
  const result = await generatePdf(
    document.getElementById("page-content").outerHTML,
    fileName
  );
  window.open(result.data.url, "_bank");
};

let fetchIntervalLesson = null;
function StudyWorksheet() {
  let { jobid } = useParams();
  const [data, setData] = useState({});
  const [status, setStatus] = useState("wait");
  const [loading, setLoading] = useState(true);
  const [openBuildingInProgressDialog, setOpenBuildingInProgressDialog] =
    useState(false);

  useEffect(() => {
    if (status === "done") {
      if (openBuildingInProgressDialog) setOpenBuildingInProgressDialog(false);
    }
    if (status === "in progress") {
      setOpenBuildingInProgressDialog(true);
    }
  }, [status]);

  const getData = async () => {
    await getResource(jobid).then((res) => {
      setData(res.data);
      setStatus(_.get(res, "data.status", ""));
      if (_.get(res, "data.status", "") === "done") {
        if (fetchIntervalLesson) {
          clearInterval(fetchIntervalLesson);
        }
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getData();
    fetchIntervalLesson = setInterval(() => {
      getData();
    }, 3000); // 5sec
    return () => {
      if (fetchIntervalLesson) {
        clearInterval(fetchIntervalLesson);
      }
    };
  }, []);

  if (loading) return <Loading />;

  const handleExportWord = async () => {
    const input = document.getElementById("page-content");

    const contentHTML = input.innerHTML;

    var converted = HtmlDocx.asBlob(contentHTML);
    saveAs(converted, `Worksheet - ${_.get(data, "name", "")}.docx`);
  };

  const styleTable = {
    width: "100%",
    borderCollapse: "collapse",
    fontSize: "16px",
    pageBreakInside: "avoid",
    boxSizing: "border-box",
  };

  const styleTh = {
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "8px",
    border: "1px solid #000000",
    webkitPrintColorAdjust: "exact",
    height: "3em",
    lineHeight: "1.5em",
    minHeight: "3em",
    boxSizing: "border-box",
  };

  const styleTd = {
    padding: "8px",
    border: "1px solid #000000",
    fontSize: "16px",
    pageBreakInside: "avoid",
    pageBreakAfter: "auto",
    height: "3em",
    lineHeight: "1.5em",
    minHeight: "3em",
    boxSizing: "border-box",
  };

  const styleTr = {
    pageBreakInside: "avoid",
    pageBreakAfter: "auto",
    boxSizing: "border-box",
  };

  const styleP0 = {
    padding: "0px",
    boxSizing: "border-box",
  };

  const styleMsqWrapper = {
    display: "flex",
    textAlign: "left",
    minHeight: "3em",
    boxSizing: "border-box",
    padding: "1px",
  };

  const styleQTitle = {
    padding: "5px",
    width: "125px",
    textAlign: "center",
    overflow: "hidden",
    boxSizing: "border-box",
  };

  const styleMcqQ = {
    padding: "5px",
    flex: 1,
  };

  const styleMcqTd = {
    padding: "10px 20px 10px 130px",
  };

  const styleGreenColor = {
    background: "#d2fcd7",
  };
  const styleTableWrapper = {
    padding: "20px 0px",
  };

  const pageBreakStyle = {
    pageBreakBefore: "always",
  };

  return (
    <>
      <div className="text-center" style={{ marginTop: "8px" }}>
        <label htmlFor="">Download as: </label>
        <img
          src={pdfIcon}
          onClick={() => {
            setLoading(true);
            pdfExport(`Worksheet - ${_.get(data, "name", "")}.pdf`).then(() =>
              setLoading(false)
            );
          }}
          alt="pdf"
          className="cursor-pointer"
          height={28}
        />
        <img
          src={wordIcon}
          onClick={() => handleExportWord()}
          alt="word"
          className="cursor-pointer"
          height={28}
        />
      </div>
      <div
        id="page-content"
        style={{
          width: "210mm",
          minWidth: "210mm",
          margin: "auto",
          fontFamily: "'Open Sans', sans-serif",
          padding: "0px 30px",
          color: "#000000",
          letterSpacing: "normal",
        }}
      >
        <h1
          style={{
            fontSize: "2em",
            fontWeight: "bold",
            fontFamily: "'Open Sans', sans-serif",
            textAlign: "center",
          }}
        >
          {data.name || data.url}
        </h1>
        <div style={{ ...styleTableWrapper }}>
          <table style={{ ...styleTable }}>
            <tr style={{ ...styleTr }}>
              <th style={{ ...styleTh, ...styleGreenColor }}>Term</th>
              <th style={{ ...styleTh, width: "37.5%", ...styleGreenColor }}>
                Definition
              </th>
              <th style={{ ...styleTh, width: "37.5%", ...styleGreenColor }}>
                Example Sentence
              </th>
            </tr>
          </table>
          {_.map(data.key_terms, (term) => (
            <table style={{ ...styleTable }}>
              <tr style={{ ...styleTr }}>
                <td style={{ ...styleTd }}>
                  {_.isObject(term) ? term.term : term}
                </td>
                <td style={{ ...styleTd, width: "37.5%" }}>
                  {_.get(term, "definition", "")}
                </td>
                <td style={{ ...styleTd, width: "37.5%" }}>
                  {_.get(term, "example_sentence", "")}
                </td>
              </tr>
            </table>
          ))}
        </div>
        <div style={{ ...styleTableWrapper }}>
          <table style={{ ...styleTable }}>
            <tr style={{ ...styleTr }}>
              <th style={{ ...styleTh, ...styleGreenColor }}>
                Detailed explanation
              </th>
            </tr>
            <tr style={{ ...styleTr }}>
              <td style={{ ...styleTd }}>{data.detailed_explanation}</td>
            </tr>
          </table>
        </div>
        <div style={{ ...styleTableWrapper }}>
          <table style={{ ...styleTable }}>
            <tr style={{ ...styleTr }}>
              <th style={{ ...styleTh, ...styleGreenColor }}>
                Reading Summary
              </th>
            </tr>
            <tr style={{ ...styleTr }}>
              <td style={{ ...styleTd }}>{data.summary}</td>
            </tr>
          </table>
        </div>

        <div style={{ ...styleTableWrapper, ...pageBreakStyle }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Multiple Choice Questions
          </h2>

          {_.get(data, "MCQs", []).map((mcq, index) => (
            <>
              <table style={{ ...styleTable }}>
                <tr style={{ ...styleTr }}>
                  <th style={{ ...styleTh, ...styleP0 }}>
                    <div style={{ ...styleMsqWrapper }}>
                      <div style={{ ...styleQTitle, ...styleGreenColor }}>
                        Question #{index + 1}
                      </div>
                      <div style={{ ...styleMcqQ }}>{mcq.question}</div>
                    </div>
                  </th>
                </tr>
                <tr style={{ ...styleTr }}>
                  <td style={{ ...styleTd, ...styleMcqTd }}>
                    {_.get(mcq, "options", []).map((option, index) => (
                      <>
                        {String.fromCharCode(65 + index)}. {option}
                        <br />
                      </>
                    ))}
                    <br />
                  </td>
                </tr>
              </table>
            </>
          ))}
        </div>

        <div style={{ ...styleTableWrapper, ...pageBreakStyle }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Short Answer Questions
          </h2>

          {_.get(data, "short_answer_questions", []).map((mcq, index) => (
            <>
              <table style={{ ...styleTable }}>
                <tr style={{ ...styleTr }}>
                  <th style={{ ...styleTh, ...styleP0 }}>
                    <div style={{ ...styleMsqWrapper }}>
                      <div style={{ ...styleQTitle, ...styleGreenColor }}>
                        Question #{index + 1}
                      </div>
                      <div style={{ ...styleMcqQ }}>{mcq.question}</div>
                    </div>
                  </th>
                </tr>
                <tr style={{ ...styleTr }}>
                  <td style={{ ...styleTd }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </td>
                </tr>
              </table>
            </>
          ))}
        </div>

        <div style={{ ...styleTableWrapper, ...pageBreakStyle }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Open Ended Question
          </h2>

          {[_.get(data, "open_ended_question", {})].map((mcq, index) => (
            <>
              <table style={{ ...styleTable }}>
                <tr style={{ ...styleTr }}>
                  <th style={{ ...styleTh, ...styleP0 }}>
                    <div style={{ ...styleMsqWrapper }}>
                      <div style={{ ...styleQTitle, ...styleGreenColor }}>
                        Question #{index + 1}
                      </div>
                      <div style={{ ...styleMcqQ }}>{mcq.question}</div>
                    </div>
                  </th>
                </tr>
                <tr style={{ ...styleTr }}>
                  <td style={{ ...styleTd }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </td>
                </tr>
              </table>
            </>
          ))}
        </div>

        <div style={{ ...styleTableWrapper, ...pageBreakStyle }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Exam Questions
          </h2>

          {_.get(data, "exam_questions", []).map((mcq, index) => (
            <>
              <table style={{ ...styleTable }}>
                <tr style={{ ...styleTr }}>
                  <th style={{ ...styleTh, ...styleP0 }}>
                    <div style={{ ...styleMsqWrapper }}>
                      <div style={{ ...styleQTitle, ...styleGreenColor }}>
                        Question #{index + 1}
                      </div>
                      <div style={{ ...styleMcqQ }}>{mcq.question}</div>
                    </div>
                  </th>
                </tr>
                <tr style={{ ...styleTr }}>
                  <td style={{ ...styleTd }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </td>
                </tr>
              </table>
            </>
          ))}

          <div style={{ textAlign: "center", padding: "15px" }}>
            Generated on{" "}
            <a
              href="https://lessen.ai/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#0075ff", textDecoration: "none" }}
            >
              lessen.ai
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudyWorksheet;
