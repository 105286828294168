import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getLesson } from "services/AI";
import _ from "lodash";
import PageLayout from "component/PageLayout";
import "./style.scss";
import { editIcon } from "assets";
import { Paths } from "routes";
import Loading from "component/Loading";
import { extractImages } from "utils";
import BuildingInProgressDialog from "dialogs/BuildingInProgressDialog";
import LessonSummeryItem from "component/LessonSummeryItem";
import ResourcesViewDialog from "dialogs/ResourcesViewDialog";

export function getSlideContentList(jsonData = []) {
  let slideContentList = [];

  jsonData.forEach((slide) => {
    let slideContent = {
      slideTitle: null,
      contentTitles: [],
      titleId: null,
    };

    _.get(slide, "sections", []).forEach((section, index) => {
      if (section.type === "title") {
        if (slideContent.slideTitle)
          slideContent.contentTitles.push(section.content);
        else {
          slideContent.slideTitle = section.content;
          slideContent.titleId = section.id;
          slideContent.titleIndex = index;
        }
      } else if (section.type === "content" && section.key) {
        slideContent.contentTitles.push(section.key);
      }
    });

    slideContentList.push(slideContent);
  });

  return slideContentList;
}

let fetchIntervalLesson = null;
function Lesson() {
  let { jobid } = useParams();
  const [slideData, setSlideData] = useState({ slides: [] });
  const [status, setStatus] = useState("wait");
  const [loading, setLoading] = useState(true);
  const [openBuildingInProgressDialog, setOpenBuildingInProgressDialog] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (status === "done") {
      if (openBuildingInProgressDialog) setOpenBuildingInProgressDialog(false);
    }
    if (status === "in progress") {
      setOpenBuildingInProgressDialog(true);
    }
  }, [status]);

  const getSlideData = async () => {
    await getLesson(jobid).then((res) => {
      setSlideData(res.data);
      setStatus(_.get(res, "data.status", ""));
      if (
        _.get(res, "data.status", "") === "done" &&
        _.get(res, "data.studyMaterial.content", "") &&
        _.get(res, "data.summary.content", "") &&
        _.get(res, "data.keyWords.content", "")
      ) {
        if (fetchIntervalLesson) {
          clearInterval(fetchIntervalLesson);
        }
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getSlideData();
    fetchIntervalLesson = setInterval(() => {
      getSlideData();
    }, 5000); // 5sec
    return () => {
      if (fetchIntervalLesson) {
        clearInterval(fetchIntervalLesson);
      }
    };
  }, []);

  const image = _.get(
    extractImages(_.get(slideData, "slides", [])),
    "[0]",
    null
  );
  if (loading) return <Loading />;
  return (
    <div className="lesson-body">
      <PageLayout
        title={
          window.innerWidth < 600
            ? "Overview"
            : "Lessen AI: Home > Lesson Overview"
        }
        className={"create-lesson"}
        hasBack={true}
        onBackClick={() => {
          navigate(Paths.Home);
        }}
      >
        <div className="lesson-wrapper">
          <div className="lesson">
            {(image || _.get(slideData, "lessenDetails.summary", "")) && (
              <div className="left-section">
                {image && <img src={image} alt="lesson-slide" />}
                <div className="lesson-title">
                  {_.get(slideData, "lessenDetails.title", "")}
                </div>
                <div className="summary">
                  {_.get(slideData, "lessenDetails.summary", "")}
                </div>
              </div>
            )}
            <div className="right-section">
              <div className="slide-list">
                {_.map(getSlideContentList(slideData.slides), (si, i) => (
                  <LessonSummeryItem si={si} i={i} />
                ))}
              </div>
            </div>
          </div>
          <div className="btn-wrapper">
            <div
              className="black-btn"
              onClick={() => {
                if (status === "in progress") {
                  setOpenBuildingInProgressDialog(true);
                } else
                  navigate(
                    Paths.Presentation.replace(":jobid", jobid).replace(
                      ":mode",
                      "edit"
                    )
                  );
              }}
            >
              <img src={editIcon} alt="whitePlusIcon" />
              <span>Edit Lesson</span>
            </div>
            <div className="space-between"></div>
            <ResourcesViewDialog
              lessenTitle={_.get(slideData, "lessenDetails.title", "")}
              lessenId={jobid}
              slideData={slideData}
            >
              <div className="black-btn btn-get-resources">Get Resources</div>
            </ResourcesViewDialog>
            <div
              className="blue-btn"
              onClick={() => {
                try {
                  if (!document.fullscreenElement) {
                    document.documentElement.requestFullscreen();
                  }
                } catch (err) {
                  console.log("error in fullscreen", err);
                }
                navigate(
                  Paths.Presentation.replace(":jobid", jobid).replace(
                    ":mode",
                    "present"
                  )
                );
              }}
            >
              Start Presenting
            </div>
          </div>
        </div>
      </PageLayout>
      <BuildingInProgressDialog
        open={openBuildingInProgressDialog}
        setOpen={setOpenBuildingInProgressDialog}
      />
    </div>
  );
}

export default Lesson;
