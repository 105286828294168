import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import "./style.scss";
import { dialogCloseIcon } from "assets";
import LoadingDots from "component/LoadingDots";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDialog = (props) => {
  const {
    children,
    deleteback,
    className = "",
    text = "Are you sure you want to delete selected lessons?",
    subText = "You will lose all work associated to this Lesson(s). This action can’t be undone.",
  } = props;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  useEffect(() => {
    // if (open) {
    //   if (!window.history.state?.popupOpen)
    //     window.history.pushState({ popupOpen: true }, "");
    // } else {
    //   if (window.history.state?.popupOpen) {
    //     window.history.back();
    //   }
    // }
    // const handleBackButton = (e) => {
    //   if (open) {
    //     handleClose();
    //     if (e) {
    //       e.stopPropagation();
    //       e.preventDefault();
    //     }
    //   }
    // };
    // window.addEventListener("popstate", handleBackButton);
    // return () => window.removeEventListener("popstate", handleBackButton);
  }, [open]);

  return (
    <>
      <span onClick={handleClickOpen} className={className}>
        {children}
      </span>
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: { borderRadius: "32px", overflowY: "unset", maxWidth: "660px" },
          }}
        >
          <DialogContent sx={{ borderRadius: "32px" }}>
            <DialogTitle sx={{ padding: 0 }}>
              <img
                src={dialogCloseIcon}
                alt="dialogCloseIcon"
                className="dialogCloseIcon"
                style={{
                  position: "absolute",
                  top: "-46px",
                  right: "-74px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
            </DialogTitle>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="delete-dialog">
                <label>{text}</label>
                {subText && <div className="sub-text">{subText}</div>}
                <div className="btn-wrapper">
                  {loading ? (
                    <div className="loading-wrapper">
                      <LoadingDots />
                    </div>
                  ) : (
                    <>
                      <div
                        className="blue-btn red-btn"
                        onClick={async (e) => {
                          e.stopPropagation();
                          setLoading(true);
                          await deleteback();
                          handleClose();
                          setLoading(false);
                        }}
                      >
                        Yes, Delete
                      </div>
                      <div className="blue-btn" onClick={handleClose}>
                        Cancel
                      </div>
                    </>
                  )}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default DeleteDialog;
