import { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import "./style.scss";
import { dialogCloseIcon } from "assets";
import { LoadingInline } from "component/Loading2";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BuildingInProgressDialog = (props) => {
  const {
    open,
    setOpen,
    btnText = "Okay",
    text = "We’re currently building your slides in the background. You can only edit once they’re complete!",
    timingText = "This can take up to 1-2 minutes",
  } = props;

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (setOpen) setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { borderRadius: "32px", overflowY: "unset", maxWidth: "554px" },
        }}
      >
        <DialogContent sx={{ borderRadius: "32px" }}>
          <DialogTitle sx={{ padding: 0 }}>
            <img
              src={dialogCloseIcon}
              alt="dialogCloseIcon"
              className="dialogCloseIcon"
              style={{
                position: "absolute",
                top: "-46px",
                right: "-74px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="building-in-progress-dialog">
              <label>
                {text}
                <small>[{timingText}]</small>
              </label>
              <div className="loading-inline">
                <LoadingInline />
              </div>
              <div className="btn-wrapper">
                <div className="btn blue-btn btn-v-big" onClick={handleClose}>
                  {btnText}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BuildingInProgressDialog;
