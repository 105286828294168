export const specificationLinks = {
  KS3: {
    Maths: {
      AQA: "https://filestore.aqa.org.uk/resources/mathematics/specifications/AQA-8300-SP-2015.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/mathematics/2015/specification-and-sample-assesment/gcse-maths-2015-specification.pdf",
      WJEC: "https://www.wjec.co.uk/media/u3rbzz1p/wjec-gcse-maths-spec-from-2015-r-e.pdf",
      OCR: "https://www.ocr.org.uk/Images/168982-specification-gcse-mathematics.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Mathematics%20%282017%29/GCSE%20Mathematics%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/h-course-spec-mathematics.pdf",
    },
    Biology: {
      AQA: "https://filestore.aqa.org.uk/resources/biology/specifications/AQA-8461-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Science/2016/Specification/GCSE_Biology_Spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/aaslc0sm/wjec-gcse-biology-spec-from-2016-e.pdf",
      OCR: "https://www.ocr.org.uk/images/234594-specification-accredited-gcse-gateway-science-suite-biology-a-j247.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Biology%20%282017%29/GCSE%20Biology%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/h-course-spec-biology.pdf",
    },
    Chemistry: {
      AQA: "https://filestore.aqa.org.uk/resources/chemistry/specifications/AQA-8462-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Science/2016/Specification/GCSE_Chemistry_Spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/jsin2au2/wjec-gcse-chemistry-spec-from-2016.pdf",
      OCR: "https://www.ocr.org.uk/images/234598-specification-accredited-gcse-gateway-science-suite-chemistry-a-j248.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Chemistry%20%282017%29/GCSE%20Chemistry%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecChemistry.pdf",
    },
    Physics: {
      AQA: "https://filestore.aqa.org.uk/resources/physics/specifications/AQA-8463-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Science/2016/Specification/GCSE_Physics_Spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/0drne4lc/wjec-gcse-physics-spec-from-2016.pdf",
      OCR: "https://www.ocr.org.uk/Images/234600-specification-accredited-gcse-gateway-science-suite-physics-a-j249.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Physics%20%282017%29/GCSE%20Physics%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/N5CourseSpecPhysics.pdf",
    },
    Economics: {
      AQA: "https://filestore.aqa.org.uk/resources/economics/specifications/AQA-8136-SP-2017.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/International%20GCSE/Economics/2017/Specification%20and%20SAMS/international-gcse-spec-9781446942789.pdf",
      WJEC: "N/A",
      OCR: "https://www.ocr.org.uk/Images/306377-specification-accredited-gcse-economics-j205.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Economics%20%282017%29/GCSE%20Economics%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecEconomics.pdf",
    },
    History: {
      AQA: "https://filestore.aqa.org.uk/resources/history/specifications/AQA-8145-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/History/2016/specification-and-sample-assessments/gcse-9-1-history-specification.pdf",
      WJEC: "https://www.wjec.co.uk/media/gzsn4pg5/wjec-gcse-history-spec-from-2017-e.pdf",
      OCR: "https://www.ocr.org.uk/Images/207163-specification-accredited-gcse-history-a-first-teaching-2019-with-first-assessment-2021-j410.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20History%20%282017%29/GCSE%20History%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/n5-course-spec-history.pdf",
    },
    "Religious-Studies": {
      AQA: "https://filestore.aqa.org.uk/resources/rs/specifications/AQA-8062-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Religious%20Studies/2016/Specification%20and%20sample%20assessments/Specification-GCSE-L1-L2-Religious-Studies-A-June-2016-Draft-4.pdf",
      WJEC: "https://www.wjec.co.uk/umbraco/surface/blobstorage/download?nodeId=8889",
      OCR: "https://www.ocr.org.uk/Images/240547-specification-accredited-gcse-religious-studies-j625.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Religious%20Studies%20%282017%29/GCSE%20Religious%20Studies%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecRMPS.pdf",
    },
    Psychology: {
      AQA: "https://filestore.aqa.org.uk/resources/psychology/specifications/AQA-8182-SP-2017.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Psychology/2017/Specification%20and%20sample%20assessments/GCSE-Psychology-draft-specification.pdf",
      WJEC: "N/A",
      OCR: "https://www.ocr.org.uk/images/309306-specification-accredited-gcse-psychology-j203.pdf",
      CCEA: "N/A",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/h-course-spec-psychology.pdf",
    },
    Sociology: {
      AQA: "https://filestore.aqa.org.uk/resources/sociology/specifications/AQA-8192-SP-2017.PDF",
      Edexcel: "N/A",
      WJEC: "https://www.wjec.co.uk/media/ysbbv1j4/eduqas-gcse-sociology-spec-from-2017-e.pdf",
      OCR: "https://www.ocr.org.uk/Images/82579-specification.pdf",
      CCEA: "N/A",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecSociology.pdf",
    },
    "Business-Studies": {
      AQA: "https://filestore.aqa.org.uk/resources/business/specifications/AQA-8132-SP-2017.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Business/2017/specification-and-sample-assessments/gcse-business-spec-2017.pdf",
      WJEC: "https://www.wjec.co.uk/media/o21oxdqx/wjec-gcse-business-spec-from-2017-22-06-16.pdf",
      OCR: "https://www.ocr.org.uk/Images/304213-specification-accredited-gcse-business-j204.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Business%20Studies%20%282017%29/GCSE%20Business%20Studies%20%282017%29-specification-Standard.pdf",
      SQA: "NA",
    },
    Geography: {
      AQA: "https://filestore.aqa.org.uk/resources/geography/specifications/AQA-8035-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Geography-A/2016/specification-and-sample-assessments/Geography_A_Issue3%20GCSE%20%289-1%29%20Specification.pdf",
      WJEC: "https://www.wjec.co.uk/media/mlyil2jr/wjec-gcse-geography-spec-from-2016-e.pdf",
      OCR: "https://www.ocr.org.uk/images/207307-specification-accredited-gcse-geography-b-j384.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Geography%20%282017%29/GCSE%20Geography%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/n5-geography-course-specification.pdf",
    },
  },
  GCSE: {
    Maths: {
      AQA: "https://filestore.aqa.org.uk/resources/mathematics/specifications/AQA-8300-SP-2015.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/mathematics/2015/specification-and-sample-assesment/gcse-maths-2015-specification.pdf",
      WJEC: "https://www.wjec.co.uk/media/u3rbzz1p/wjec-gcse-maths-spec-from-2015-r-e.pdf",
      OCR: "https://www.ocr.org.uk/Images/168982-specification-gcse-mathematics.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Mathematics%20%282017%29/GCSE%20Mathematics%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/h-course-spec-mathematics.pdf",
    },
    Biology: {
      AQA: "https://filestore.aqa.org.uk/resources/biology/specifications/AQA-8461-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Science/2016/Specification/GCSE_Biology_Spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/aaslc0sm/wjec-gcse-biology-spec-from-2016-e.pdf",
      OCR: "https://www.ocr.org.uk/images/234594-specification-accredited-gcse-gateway-science-suite-biology-a-j247.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Biology%20%282017%29/GCSE%20Biology%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/h-course-spec-biology.pdf",
    },
    Chemistry: {
      AQA: "https://filestore.aqa.org.uk/resources/chemistry/specifications/AQA-8462-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Science/2016/Specification/GCSE_Chemistry_Spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/jsin2au2/wjec-gcse-chemistry-spec-from-2016.pdf",
      OCR: "https://www.ocr.org.uk/images/234598-specification-accredited-gcse-gateway-science-suite-chemistry-a-j248.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Chemistry%20%282017%29/GCSE%20Chemistry%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecChemistry.pdf",
    },
    Physics: {
      AQA: "https://filestore.aqa.org.uk/resources/physics/specifications/AQA-8463-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Science/2016/Specification/GCSE_Physics_Spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/0drne4lc/wjec-gcse-physics-spec-from-2016.pdf",
      OCR: "https://www.ocr.org.uk/Images/234600-specification-accredited-gcse-gateway-science-suite-physics-a-j249.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Physics%20%282017%29/GCSE%20Physics%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/N5CourseSpecPhysics.pdf",
    },
    Economics: {
      AQA: "https://filestore.aqa.org.uk/resources/economics/specifications/AQA-8136-SP-2017.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/International%20GCSE/Economics/2017/Specification%20and%20SAMS/international-gcse-spec-9781446942789.pdf",
      WJEC: "N/A",
      OCR: "https://www.ocr.org.uk/Images/306377-specification-accredited-gcse-economics-j205.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Economics%20%282017%29/GCSE%20Economics%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecEconomics.pdf",
    },
    History: {
      AQA: "https://filestore.aqa.org.uk/resources/history/specifications/AQA-8145-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/History/2016/specification-and-sample-assessments/gcse-9-1-history-specification.pdf",
      WJEC: "https://www.wjec.co.uk/media/gzsn4pg5/wjec-gcse-history-spec-from-2017-e.pdf",
      OCR: "https://www.ocr.org.uk/Images/207163-specification-accredited-gcse-history-a-first-teaching-2019-with-first-assessment-2021-j410.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20History%20%282017%29/GCSE%20History%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/n5-course-spec-history.pdf",
    },
    "Religious-Studies": {
      AQA: "https://filestore.aqa.org.uk/resources/rs/specifications/AQA-8062-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Religious%20Studies/2016/Specification%20and%20sample%20assessments/Specification-GCSE-L1-L2-Religious-Studies-A-June-2016-Draft-4.pdf",
      WJEC: "https://www.wjec.co.uk/umbraco/surface/blobstorage/download?nodeId=8889",
      OCR: "https://www.ocr.org.uk/Images/240547-specification-accredited-gcse-religious-studies-j625.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Religious%20Studies%20%282017%29/GCSE%20Religious%20Studies%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecRMPS.pdf",
    },
    Psychology: {
      AQA: "https://filestore.aqa.org.uk/resources/psychology/specifications/AQA-8182-SP-2017.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Psychology/2017/Specification%20and%20sample%20assessments/GCSE-Psychology-draft-specification.pdf",
      WJEC: "N/A",
      OCR: "https://www.ocr.org.uk/images/309306-specification-accredited-gcse-psychology-j203.pdf",
      CCEA: "N/A",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/h-course-spec-psychology.pdf",
    },
    Sociology: {
      AQA: "https://filestore.aqa.org.uk/resources/sociology/specifications/AQA-8192-SP-2017.PDF",
      Edexcel: "N/A",
      WJEC: "https://www.wjec.co.uk/media/ysbbv1j4/eduqas-gcse-sociology-spec-from-2017-e.pdf",
      OCR: "https://www.ocr.org.uk/Images/82579-specification.pdf",
      CCEA: "N/A",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecSociology.pdf",
    },
    "Business-Studies": {
      AQA: "https://filestore.aqa.org.uk/resources/business/specifications/AQA-8132-SP-2017.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Business/2017/specification-and-sample-assessments/gcse-business-spec-2017.pdf",
      WJEC: "https://www.wjec.co.uk/media/o21oxdqx/wjec-gcse-business-spec-from-2017-22-06-16.pdf",
      OCR: "https://www.ocr.org.uk/Images/304213-specification-accredited-gcse-business-j204.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Business%20Studies%20%282017%29/GCSE%20Business%20Studies%20%282017%29-specification-Standard.pdf",
      SQA: "NA",
    },
    Geography: {
      AQA: "https://filestore.aqa.org.uk/resources/geography/specifications/AQA-8035-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/GCSE/Geography-A/2016/specification-and-sample-assessments/Geography_A_Issue3%20GCSE%20%289-1%29%20Specification.pdf",
      WJEC: "https://www.wjec.co.uk/media/mlyil2jr/wjec-gcse-geography-spec-from-2016-e.pdf",
      OCR: "https://www.ocr.org.uk/images/207307-specification-accredited-gcse-geography-b-j384.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCSE/GCSE%20Geography%20%282017%29/GCSE%20Geography%20%282017%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/n5-geography-course-specification.pdf",
    },
  },
  "A-Level": {
    Maths: {
      AQA: "https://filestore.aqa.org.uk/resources/mathematics/specifications/AQA-7357-SP-2017.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Mathematics/2017/specification-and-sample-assesment/a-level-l3-mathematics-specification-issue4.pdf",
      WJEC: "https://www.wjec.co.uk/media/lm3fegtu/wjec-gce-maths-spec-from-2017-e.pdf",
      OCR: "https://ocr.org.uk/Images/308723-specification-accredited-a-level-gce-mathematics-a-h240.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Mathematics%20%282018%29/GCE%20Mathematics%20%282018%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/AHCourseSpecMathematics.pdf",
    },
    "Further-Maths": {
      AQA: "https://filestore.aqa.org.uk/resources/mathematics/specifications/AQA-7367-SP-2017.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Mathematics/2017/specification-and-sample-assesment/a-level-l3-further-mathematics-specification.pdf",
      WJEC: "https://www.wjec.co.uk/media/hcpnsu41/wjec-gce-further-maths-spec-from-2017-e.pdf",
      OCR: "https://ocr.org.uk/Images/308752-specification-accredited-a-level-gce-further-mathematics-a-h245.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Further%20Mathematics%20%282018%29/GCE%20Further%20Mathematics%20%282018%29-specification-Standard_0.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/AHCourseSpecMathematics.pdf",
    },
    Biology: {
      AQA: "https://filestore.aqa.org.uk/resources/biology/specifications/AQA-7401-7402-SP-2015.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/biology-b/2015/specification-and-sample-assessment-materials/9781446930892-gce2015-a-biob-spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/gcgjtvqj/wjec-gce-biology-spec-from-2015.pdf",
      OCR: "https://www.ocr.org.uk/Images/687834-download-a-level-specification.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Biology%20%282016%29/GCE%20Biology%20%282016%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/ah-course-spec-biology.pdf",
    },
    Chemistry: {
      AQA: "https://filestore.aqa.org.uk/resources/chemistry/specifications/AQA-7404-7405-SP-2015.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Chemistry/2015/Specification%20and%20sample%20assessments/a-level-chemistry-2015-specification.pdf",
      WJEC: "https://www.wjec.co.uk/media/akbbkvwh/wjec-gce-chemistry-spec-from-2015.pdf",
      OCR: "https://www.ocr.org.uk/images/171720-specification-accredited-a-level-gce-chemistry-a-h432.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Chemistry%20%282016%29/GCE%20Chemistry%20%282016%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/AHChemistryCourseSpec.pdf",
    },
    Physics: {
      AQA: "https://filestore.aqa.org.uk/resources/physics/specifications/AQA-7407-7408-SP-2015.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Physics/2015/Specification%20and%20sample%20assessments/pearsonedexcel-alevel-physics-spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/gxbjl243/wjec-gce-physics-spec-from-2015-e-22-09-22.pdf",
      OCR: "https://www.ocr.org.uk/images/171726-specification-accredited-a-level-gce-physics-a-h556.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Physics%20%282016%29/GCE%20Physics%20%282016%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecPhysics.pdf",
    },
    Economics: {
      AQA: "https://filestore.aqa.org.uk/resources/economics/specifications/AQA-7135-7136-SP-2015.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Economics/2015/specification-and-sample-assessment-materials/A_Level_Econ_A_Spec.pdf",
      WJEC: "https://www.wjec.co.uk/media/a0pbk0ys/wjec-gce-as-a-economics-specification-from-2015.pdf",
      OCR: "https://ocr.org.uk/Images/536455-specification-accredited-a-level-gce-economics-h460.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Economics%20%282016%29/GCE%20Economics%20%282016%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/ah-course-spec-economics.pdf",
    },
    History: {
      AQA: "https://filestore.aqa.org.uk/resources/history/specifications/AQA-7041-7042-SP-2015.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/History/2015/Specification%20and%20sample%20assessments/9781446914366-gce-2015-a-hist.pdf",
      WJEC: "https://www.wjec.co.uk/media/uwlondvv/wjec-gce-history-spec-from-2015-e.pdf",
      OCR: "https://www.ocr.org.uk/Images/170128-specification-accredited-a-level-gce-history-a-h505.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20History%20%282019%29/GCE%20History%20%282019%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/h-history-course-specification.pdf",
    },
    "Religious-Studies": {
      AQA: "https://filestore.aqa.org.uk/resources/rs/specifications/AQA-7062-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Religious%20Studies/2016/Specification%20and%20sample%20assessments/Specification_GCE_A_Level_in_Religious_Studies.pdf",
      WJEC: "https://www.wjec.co.uk/media/kfvcf3ky/wjec-gce-religious-studies-spec-from-2016-e-02-11-20.pdf",
      OCR: "https://www.ocr.org.uk/images/242913-specification-accredited-a-level-gce-religious-studies-h573.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Religious%20Studies%20%282016%29/GCE%20Religious%20Studies%20%282016%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecRMPS.pdf",
    },
    Psychology: {
      AQA: "https://filestore.aqa.org.uk/resources/psychology/specifications/AQA-7181-7182-SP-2015.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Psychology/2015/specification-and-sample-assessments/AL-Specification-Psychology.pdf",
      WJEC: "https://www.wjec.co.uk/media/veplsnrk/wjec-gce-psychology-spec-from-2015-e-14-05-20.pdf",
      OCR: "https://www.ocr.org.uk/Images/171732-specification-accredited-a-level-gce-psychology-h567.pdf",
      CCEA: "NA",
      SQA: "https://www.sqa.org.uk/sqa/files_ccc/h-course-spec-psychology.pdf",
    },
    Sociology: {
      AQA: "https://filestore.aqa.org.uk/resources/sociology/specifications/AQA-7191-7192-SP-2015.PDF",
      Edexcel: "NA",
      WJEC: "https://www.wjec.co.uk/media/owifffk1/wjec-gce-sociology-spec-from-2015-e-09-06-2020.pdf",
      OCR: "https://www.ocr.org.uk/Images/170212-specification-accredited-a-level-gce-sociology.pdf",
      CCEA: "NA",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecSociology.pdf",
    },
    "Business-Studies": {
      AQA: "https://filestore.aqa.org.uk/resources/business/specifications/AQA-7131-7132-SP-2023.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Business%20Studies/2015/specification-and-sample-assessment-materials/9781446914694-gce2015-a-bus-web.pdf",
      WJEC: "https://www.wjec.co.uk/media/qdyp1wcl/wjec-gce-as-a-level-business-specification-formatted-2.pdf",
      OCR: "https://www.ocr.org.uk/Images/170837-specification-accredited-a-level-gce-business-h431.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Business%20Studies%20%282016%29/GCE%20Business%20Studies%20%282016%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/HigherCourseSpecBusinessManagement.pdf",
    },
    Geography: {
      AQA: "https://filestore.aqa.org.uk/resources/geography/specifications/AQA-7037-SP-2016.PDF",
      Edexcel:
        "https://qualifications.pearson.com/content/dam/pdf/A%20Level/Geography/2016/specification-and-sample-assessments/Pearson-Edexcel-GCE-A-level-Geography-specification-issue-5-FINAL.pdf",
      WJEC: "https://www.wjec.co.uk/media/wijlspii/wjec-gce-geography-spec-from-2016-e.pdf",
      OCR: "https://www.ocr.org.uk/images/223012-specification-accredited-a-level-gce-geography-h481.pdf",
      CCEA: "https://ccea.org.uk/downloads/docs/Specifications/GCE/GCE%20Geography%20%282018%29/GCE%20Geography%20%282018%29-specification-Standard.pdf",
      SQA: "https://www.sqa.org.uk/files_ccc/AHCourseSpecGeography.pdf",
    },
  },
};
