import React, { useContext, useState } from "react";
import "./style.scss";
import PageLayout from "component/PageLayout";
import { subjects } from "data/subjects";
import _ from "lodash";
import { examBoards } from "data/examBoards";
import { studyLevels } from "data/studyLevels";
import { specificationLinks } from "data/specificationLinks";
import InfoDialog from "dialogs/InfoDialog";
import AuthContext from "contexts/authContext";

function CreateLessonPage2(props) {
  const {
    data = {
      lessonTitle: "",
      subject: "",
      studyLevel: "",
      examBoard: "",
      keyComponents: [],
      specificPoints: "",
    },
    changePage,
    onSpecChang,
    generateLessonClick,
  } = props;

  const [specificPointsState, setSpecificPointsState] = useState(
    data.specificPoints
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const { auth } = useContext(AuthContext);

  const subjectData = _.find(subjects, (s) => s.name === data.subject);
  const studyLevelData = _.find(studyLevels, (s) => s.name === data.studyLevel);
  const examBoardData = _.find(examBoards, (s) => s.name === data.examBoard);
  const link = _.get(
    specificationLinks,
    `${_.get(studyLevelData, "code", "")}.${_.get(
      subjectData,
      "code",
      ""
    )}.${_.get(examBoardData, "code", "")}`,
    "N/A"
  );
  const region = _.get(auth, "userData.Region", "uk");
  let placeholder = `Please add more context to this box. ${
    region === "uk"
      ? "You can open specification to copy the relevant points in. "
      : ""
  }This will ensure your students are learning everything they need.`;

  if (region === "us") {
    placeholder =
      "Please use this space to add any additional context for your lesson. It can be specific to your state if necessary!";
  }
  return (
    <PageLayout
      title={window.innerWidth < 768 ? "" : data.lessonTitle}
      className={"create-lesson"}
      hasBack={true}
      onBackClick={() => {
        changePage();
      }}
    >
      <div className="create-lesson-page-2">
        <div className="specification-wrapper">
          <div className="specification-header">
            <div className="title">Specification points</div>
            {region === "uk" && (
              <div
                className="black-btn btn-open-specification"
                onClick={() => {
                  if (link && link.length > 0 && link !== "N/A")
                    window.open(link, "_blank");
                  else setDialogOpen(true);
                }}
              >
                Open Specification
              </div>
            )}
          </div>
          <div className="specification-sections">
            <div className="specification-points">
              <div className="input-section">
                <textarea
                  value={specificPointsState}
                  onChange={(e) => {
                    setSpecificPointsState(e.target.value);
                    onSpecChang({ specificPoints: e.target.value });
                  }}
                  placeholder={placeholder}
                />
              </div>
            </div>
            {/* <div className="search-specific-field">
              <div className="input-wrapper">
                <input
                  className="specific-field-search"
                  placeholder="Search for specific field..."
                />
              </div>
              <div>
                {_.map(specific, (spec) => (
                  <CheckboxGroup title={spec.group_title} items={spec} />
                ))}
              </div>
            </div> */}
          </div>
        </div>
        <div className="btn-generate-wrapper">
          <div
            className="blue-btn btn-generate-lesson"
            onClick={() => {
              if (generateLessonClick) {
                generateLessonClick(specificPointsState);
              }
            }}
          >
            Generate Lesson
          </div>
        </div>
      </div>
      <InfoDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        text="No specifications are currently available for your selected subject."
      />
    </PageLayout>
  );
}

export default CreateLessonPage2;
