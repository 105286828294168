import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useNavigate } from "react-router-dom";
import {
  addBlueIcon,
  backShadBlue,
  circleLeftArrow,
  circleRightArrow,
  lessenActiveMenuIcon,
  lessenMenuIcon,
  logoutIcon,
  menuSettingIcon,
  passwordActiveMenuIcon,
  passwordMenuIcon,
  planActiveMenuIcon,
  planMenuIcon,
  whitePlusIcon,
} from "../../assets";
import "./style.scss";
import { Paths } from "routes";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import LetteredAvatar from "react-lettered-avatar";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: window.innerWidth < 750 ? window.innerWidth : drawerWidth,
  maxHeight: "1024px",
  maxWidth: window.innerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: "none",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `140px`,
  maxHeight: "1024px",
  border: "none",
  [theme.breakpoints.down("750")]: {
    width: `100px`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    background: "#2D2D2D",

    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme),

        // background: "#2D2D2D",
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme),
        // padding: "9px 19px",
      },
    }),
  };
});

export default function SideMenu() {
  const [open, setOpen] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState("Dashboard");
  const navigate = useNavigate();
  const { auth } = React.useContext(AuthContext);

  const menuOpentions = ["Home", "Password", "Your Plan"];
  const menuOpentionPaths = {
    Home: Paths.Home,
    Password: Paths.UpdatePassword,
    "Your Plan": Paths.Subscription,
    Settings: Paths.Home,
    "Log out": Paths.LogOut,
  };
  const menuCount = {};
  const menuOpentionIcons = {
    Home: lessenMenuIcon,
    Password: passwordMenuIcon,
    "Your Plan": planMenuIcon,
    Settings: menuSettingIcon,
    "Log out": logoutIcon,
  };
  const menuActiveOpentionIcons = {
    Home: lessenActiveMenuIcon,
    Password: passwordActiveMenuIcon,
    "Your Plan": planActiveMenuIcon,
    Settings: menuSettingIcon,
    "Log out": logoutIcon,
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box className="side-menu-wrapper">
      <Drawer variant="permanent" open={open} className="side-menu">
        <div className="drawer-wrapper">
          {open ? (
            <img
              onClick={handleDrawerClose}
              src={circleLeftArrow}
              className="open-icon"
              alt={"circleRightArrow"}
            />
          ) : (
            <img
              onClick={handleDrawerOpen}
              src={circleRightArrow}
              className="close-icon"
              alt={"circleRightArrow"}
            />
          )}
          <div className="drawer-header">
            {/* <img src={profileIcon} alt="profileIcon" /> */}
            <LetteredAvatar
              backgroundColor="#585657"
              name={
                _.get(auth, "userData.FirstName", "") +
                " " +
                _.get(auth, "userData.LastName", "")
              }
              size={44}
            />
            {open && (
              <div className="header-profile">
                <div className="post">TEACHER</div>
                <div className="name">
                  {_.get(auth, "userData.FirstName", "")}{" "}
                  {_.get(auth, "userData.LastName", "")}
                </div>
              </div>
            )}
          </div>
          <List className="menu-list">
            <ListItem disablePadding sx={{ display: "block", mb: "8px" }}>
              <div className={`main ${open ? "open" : "close"}`}>MAIN</div>
            </ListItem>
            {menuOpentions.map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                sx={{ display: "block", mb: "8px" }}
              >
                <ListItemButton
                  className={
                    activeMenu === text ? "active-menu menu-item" : "menu-item"
                  }
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    paddingLeft: "16px",
                    marginLeft: "24px",
                    marginRight: "24px",
                  }}
                  onClick={() => {
                    navigate(menuOpentionPaths[text]);
                    setActiveMenu(text);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      m: "auto",
                      mr: open ? "12px" : "auto",
                    }}
                  >
                    <img
                      src={
                        activeMenu === text
                          ? menuActiveOpentionIcons[text]
                          : menuOpentionIcons[text]
                      }
                      alt={menuOpentionIcons[text]}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${text}${
                      menuCount[text] ? ` (${menuCount[text]})` : ""
                    }`}
                    sx={{
                      opacity: open ? 1 : 0,
                      // maxWidth: open ? "unset" : 0,
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "32px",
                      display: "flex",
                      alignItems: "center",
                      color:
                        activeMenu !== text
                          ? "rgba(255, 255, 255, 0.56)"
                          : "#FFF",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <List sx={{ mt: "25px", mb: "10px" }}>
            {open && (
              <>
                <div className="lets-start">
                  <div className="lets-start-title">Let's start!</div>
                  <div className="lets-start-sub-title">
                    Creating or adding new tasks couldn't be easier
                  </div>
                  <div
                    className="create-lesson-btn blue-btn"
                    onClick={() => navigate(Paths.CreateLesson)}
                  >
                    <img src={whitePlusIcon} alt="whitePlusIcon" />
                    <span>Create Lesson</span>
                  </div>
                  <div
                    className="logout-btn-wrapper"
                    onClick={() => navigate(Paths.LogOut)}
                  >
                    <span>
                      <img src={logoutIcon} alt={"logout"} />
                    </span>
                    <div className="text">Logout</div>
                  </div>
                </div>
              </>
            )}
            {!open && (
              <>
                <ListItem disablePadding sx={{ display: "block", mb: "8px" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      paddingLeft: "16px",
                    }}
                    onClick={() => navigate(Paths.CreateLesson)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        m: "auto",
                        mr: open ? "auto" : "auto",
                      }}
                    >
                      <img
                        src={addBlueIcon}
                        alt={"addBlueIcon"}
                        width={"48px"}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ display: "block", mb: "12px" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      paddingLeft: "16px",
                    }}
                    onClick={() => navigate(Paths.LogOut)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        m: "auto",
                        mr: open ? "auto" : "auto",
                      }}
                    >
                      <img
                        src={
                          activeMenu === "Log out"
                            ? menuActiveOpentionIcons["Log out"]
                            : menuOpentionIcons["Log out"]
                        }
                        alt={menuOpentionIcons["Log out"]}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </>
            )}
          </List>
        </div>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          pl: open
            ? `${drawerWidth + 25}px`
            : window.innerWidth < 750
            ? "108px"
            : "165px",
          pt: "44px",
          height: "100%",
          position: "relative",
        }}
      >
        <Outlet />
        <div className="back-shad-wrapper">
          <img src={backShadBlue} alt="backShadBlue" className="back-shad" />
        </div>
      </Box>
    </Box>
  );
}
