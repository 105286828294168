import { forwardRef, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import "./style.scss";
import { dialogCloseIcon } from "assets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InfoDialog = (props) => {
  const { open, setOpen, text = "", btnText = "Okay", image } = props;

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (setOpen) setOpen(false);
  };

  useEffect(() => {
    // if (open) {
    //   if (!window.history.state?.popupOpen)
    //     window.history.pushState({ popupOpen: true }, "");
    // } else {
    //   if (window.history.state?.popupOpen) {
    //     window.history.back();
    //   }
    // }
    // const handleBackButton = (e) => {
    //   if (open) {
    //     handleClose();
    //     if (e) {
    //       e.stopPropagation();
    //       e.preventDefault();
    //     }
    //   }
    // };
    // window.addEventListener("popstate", handleBackButton);
    // return () => window.removeEventListener("popstate", handleBackButton);
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { borderRadius: "32px", overflowY: "unset" },
        }}
      >
        <DialogContent sx={{ borderRadius: "32px" }}>
          <DialogTitle sx={{ padding: 0 }}>
            <img
              src={dialogCloseIcon}
              alt="dialogCloseIcon"
              className="dialogCloseIcon"
              style={{
                position: "absolute",
                top: "-46px",
                right: "-74px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="info-dialog">
              {image && <img src={image} alt="icon" />}
              <label>{text}</label>
              <div className="btn-wrapper">
                <div className="btn blue-btn btn-v-big" onClick={handleClose}>
                  {btnText}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InfoDialog;
