const URI = Object.freeze(
  "https://cjxke6ea6d.execute-api.eu-west-1.amazonaws.com/dev/v1"
);
// const URI = Object.freeze("http://localhost:7000/v1");
export const APIURI = URI;

export const endPoints = Object.freeze({
  auth: {
    register: URI + "/auth/signup",
    sendOtp: URI + "/auth/sendOtp",
    login: URI + "/auth/login",
    getUser: URI + "/auth/getUser",
    updateUserRegion: URI + "/auth/user/region",
    sendResetPasswordOtp: URI + "/auth/send-reset-password-otp",
    validateOtp: URI + "/auth/validate-otp",
    changePassword: URI + "/auth/change-password",
    updatePassword: URI + "/auth/update-password",
  },
  ai: {
    generateLesson: URI + "/ai/generate-lessen",
    generatePdf: URI + "/ai/generate-pdf",
    getLessonById: URI + "/ai/get-lessen/:id",
    deleteLessonById: URI + "/ai/delete-lessen/:id",
    getLessons: URI + "/ai/get-lessens",
    updateLesson: URI + "/ai/update-lessen/:id",
    getUploadImageUlr: URI + "/image/upload-url?fileType=:fileType",
  },
  subscription: {
    fetchSubscription: URI + "/subscription",
    createCheckoutSession: URI + "/subscription/create-checkout-session",
  },
  resource: {
    generateFromVideo: URI + "/ai/generate-resource/from-video",
    getLessenResource: URI + "/ai/get-lessen-resource/:lessenId",
    getResource: URI + "/ai/get-resource/:resourceId",
  },
});
