/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Slide from "../Slide";
import _ from "lodash";
import "./style.scss";
import BlinkingDotComponent from "component/BlinkingDotComponent";
import Loading2 from "component/Loading2";
import { capitalizeFirstChar, extractImages } from "utils";
import { editIcon, leftBlueIcon, slideCloseIcon } from "assets";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import BuildingInProgressDialog from "dialogs/BuildingInProgressDialog";
import { updateLesson } from "services/AI";
import ImageUploadDialog from "dialogs/ImageUploadDialog";
import EditSlidesDialog from "dialogs/EditSlidesDialog";

let slideId = 1;
let sectionId = 1;
let lastStatus = "";

const handleNestedObject = (nestedObj, child = 1) => {
  let sections = [];
  for (let key in nestedObj) {
    if (typeof nestedObj[key] === "object" && nestedObj[key] !== null) {
      if (!Array.isArray(nestedObj)) {
        if (["options"].indexOf(_.toLower(key)) > -1) {
        } else {
          sections.push({
            id: "section" + sectionId++,
            content: `${key.split("_").join(" ")}`,
            type: "title",
          });
        }
      }

      if (
        ["options"].indexOf(_.toLower(key)) > -1 &&
        Array.isArray(nestedObj[key])
      ) {
        for (let o = 0; o < nestedObj[key].length; o++) {
          if (typeof nestedObj[key][o] === "string") {
            sections.push({
              id: "section" + sectionId++,
              key: null,
              content: `${"Option: " + capitalizeFirstChar(nestedObj[key][o])}`,
              type: "content",
              child: child,
            });
          } else
            sections = [
              ...sections,
              ...handleNestedObject(
                nestedObj[key][o],
                Object.keys(nestedObj[key]).length
              ),
            ];
        }
      } else {
        sections = [
          ...sections,
          ...handleNestedObject(
            nestedObj[key],
            Object.keys(nestedObj[key]).length
          ),
        ];
      }
    } else {
      if (_.toLower(key) === "title")
        sections.push({
          id: "section" + sectionId++,
          content: `${nestedObj[key].split("_").join(" ")}`,
          type: "title",
        });
      else if (_.toLower(key) === "slideimage") {
        sections.push({
          id: "section" + sectionId++,
          image: nestedObj[key],
          type: "content",
        });
      } else if (_.toLower(key) === "googlesearchterm") {
      } else {
        if (Array.isArray(nestedObj)) {
          sections.push({
            id: "section" + sectionId++,
            key: null,
            content: `${capitalizeFirstChar(nestedObj[key])}`,
            type: "content",
            child: child,
          });
        } else if (
          ["question", "marks", "answer", "options"].indexOf(_.toLower(key)) >
            -1 ||
          /^answer\d+$/.test(_.toLower(key)) ||
          /^step\d+$/.test(_.toLower(key)) ||
          /^question\d+$/.test(_.toLower(key))
        ) {
          sections.push({
            id: "section" + sectionId++,
            key: null,
            content: `${capitalizeFirstChar(key)}: ${capitalizeFirstChar(
              nestedObj[key]
            )}`,
            type: "content",
            child: child,
          });
        } else if (["content"].indexOf(_.toLower(key)) > -1) {
          sections.push({
            id: "section" + sectionId++,
            key: null,
            content: `${capitalizeFirstChar(nestedObj[key])}`,
            type: "content",
            child: child,
          });
        } else
          sections.push({
            id: "section" + sectionId++,
            key: `${key.split("_").join(" ")}:`,
            content: `${capitalizeFirstChar(nestedObj[key])}`,
            type: "content",
            child: child,
          });
      }
    }
  }
  return sections;
};

const getInitialSlides = (data) => {
  const initialSlides = Object.entries(data).map(([slideName, slideData]) => {
    let sections = [];
    sections = [...sections, ...handleNestedObject(slideData)];
    return { id: "slide" + slideId++, sections };
  });
  return initialSlides;
};

let saveInterval = null;

const Presentation = ({
  slidesData = [],
  data,
  status = "wait-unknown",
  jobid = 0,
  sSlideId,
  sSectionId,
  mode = "present",
  getSlideData,
}) => {
  const [slides, setSlides] = useState(
    _.cloneDeep(slidesData.length > 0 ? slidesData : getInitialSlides(data))
  );

  const [savedSlides, setSavedSlides] = useState(
    _.cloneDeep(slidesData.length > 0 ? slidesData : getInitialSlides(data))
  );
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSection, setActiveSection] = useState(0);
  const [isPresenting, setIsPresenting] = useState(mode === "present");
  const [isEditSlideOpen, setEditSlideOpen] = useState(false);

  const [sortCutKeysActive, setSortCutKeysActive] = useState(true);
  const [openBuildingInProgressDialog, setOpenBuildingInProgressDialog] =
    useState(false);
  const [, setActiveCollectionSection] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(
    window.innerWidth < 600 ? 0.66 : +localStorage.getItem("zoomLevel") || 1
  );
  const [loading, setLoading] = useState(false);
  const [newImageIndex, setNewImageIndex] = useState(-1);

  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   saveUnsaveChanges();
  //   saveInterval = setInterval(saveUnsaveChanges, 5000); // 10sec
  //   return () => {
  //     if (saveInterval) {
  //       clearInterval(saveInterval);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    setIsPresenting(mode === "present");
    getSlideData();
    setLoading(false);
    setActiveSection(0);
  }, [mode]);

  useEffect(() => {
    slideId = sSlideId;
    sectionId = sSectionId;
  }, [sSlideId, sSectionId]);

  useEffect(() => {
    if (lastStatus === "in progress" && status === "done") {
      if (openBuildingInProgressDialog) setOpenBuildingInProgressDialog(false);
      navigate(Paths.Lesson.replace(":jobid", jobid));
    }
    if (lastStatus !== status && status === "in progress") {
      setOpenBuildingInProgressDialog(true);
    }
    lastStatus = status;
  }, [status]);

  useEffect(() => {
    setSlides(
      _.cloneDeep(slidesData.length > 0 ? slidesData : getInitialSlides(data))
    );
    setSavedSlides(
      _.cloneDeep(slidesData.length > 0 ? slidesData : getInitialSlides(data))
    );
    if (status === "in progress") {
      const slideLength = _.cloneDeep(
        slidesData.length > 0 ? slidesData : getInitialSlides(data)
      ).length;
      setActiveSlide(slideLength - 1);
    }
  }, [data, slidesData]);

  useEffect(() => {
    const images = extractImages(slides);
    for (let i = 0; i < images.length; i++) {
      const img1 = new Image();
      img1.src = images[i];
    }
    if (activeSlide > slides.length - 1 && isEditSlideOpen) {
      setActiveSlide(slides.length - 1);
    }
  }, [slides]);

  useEffect(() => {
    document.documentElement.style.setProperty("--slide-zoom-level", zoomLevel);
    localStorage.setItem("zoomLevel", zoomLevel);
  }, [zoomLevel]);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.code === "Space" || event.code === "ArrowDown") {
        event.preventDefault();
        gotoNextSection();
      }
      if (event.code === "ArrowRight") {
        event.preventDefault();
        gotoNextSlide();
      }
      if (event.code === "ArrowUp") {
        event.preventDefault();
        gotoBackSection();
      }
      if (event.code === "ArrowLeft" || event.code === "Backspace") {
        event.preventDefault();
        gotoBackSlide();
      }
      if (event.code === "Plus") {
        event.preventDefault();
        setZoomLevel((current) => current + 0.1);
      }
      if (event.code === "Minus") {
        event.preventDefault();
        setZoomLevel((current) => current + 0.1);
      }
    };
    if (sortCutKeysActive) {
      window.addEventListener("keydown", handleKeydown);
    } else {
      window.removeEventListener("keydown", handleKeydown);
    }
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [sortCutKeysActive, slides, activeSlide, activeSection]);

  const slidesRef = useRef(slides);
  const savedSlidesRef = useRef(savedSlides);

  useEffect(() => {
    slidesRef.current = slides;
    savedSlidesRef.current = savedSlides;
  }, [slides, savedSlides]);

  const saveUnsaveChangesOut = async () => {
    const lastslides = _.cloneDeep(slides);
    if (
      !isPresenting &&
      !(JSON.stringify(savedSlides) === JSON.stringify(slides)) &&
      lastslides &&
      lastslides.length > 0
    ) {
      await updateLesson(jobid, lastslides, slideId, sectionId);
      setSavedSlides(lastslides); // Note: This will still update the state and cause a re-render
    }
  };

  useEffect(() => {
    const saveUnsaveChanges = async () => {
      const lastslides = _.cloneDeep(slidesRef.current);
      if (
        !isPresenting &&
        !(
          JSON.stringify(savedSlidesRef.current) === JSON.stringify(lastslides)
        ) &&
        lastslides &&
        lastslides.length > 0
      ) {
        await updateLesson(jobid, lastslides, slideId, sectionId);
        setSavedSlides(lastslides); // Note: This will still update the state and cause a re-render
      }
    };

    saveUnsaveChanges();
    saveInterval = setInterval(saveUnsaveChanges, 5000); // Run every 10 seconds
    return () => {
      if (saveInterval) {
        clearInterval(saveInterval);
      }
    };
  }, []);

  const onContentChange = (
    sectionIndex,
    newContent,
    key,
    isCollection = false,
    collectionId,
    activeSlideIndex = activeSlide
  ) => {
    const newSlides = slides.slice();
    if (isCollection) {
      newSlides[activeSlideIndex].sections[sectionIndex].collection[
        collectionId
      ][key] = newContent;
    } else newSlides[activeSlideIndex].sections[sectionIndex][key] = newContent;
    setSlides(newSlides);
  };

  // const onAddSlide = () => {
  //   const newSlide = slideId++;
  //   setSlides([
  //     ...slides,
  //     {
  //       id: "slide" + newSlide,
  //       sections: [
  //         { id: "section" + newSlide, content: "Content of new slide" },
  //       ],
  //     },
  //   ]);
  //   setActiveSlide(slides.length);
  // };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(slides[activeSlide].sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const newSlides = slides.slice();
    newSlides[activeSlide].sections = items;
    setSlides(newSlides);
  };

  const gotoNextSection = () => {
    setActiveSection((prevSection) => {
      setActiveCollectionSection(0);
      if (prevSection + 1 < slides[activeSlide].sections.length) {
        return prevSection + 1; // next section
      } else {
        setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length); // next slide
        return 0; // reset section
      }
    });
  };

  const gotoNextSlide = () => {
    setActiveSection(0);
    setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length); // next slide
  };

  const gotoBackSection = () => {
    if (activeSection > 0) {
      setActiveSection(activeSection - 1);
    } else {
      if (activeSlide > 0) {
        const newSlide = activeSlide - 1;
        setActiveSlide(newSlide);
        setActiveSection(slides[newSlide].sections.length - 1);
      }
    }
  };

  const gotoBackSlide = () => {
    if (activeSlide > 0) {
      const newSlide = activeSlide - 1;
      setActiveSection(0);
      setActiveSlide(newSlide);
    }
  };

  const slideBackForward = [];
  const slidePages = [];
  if (isPresenting) {
    slideBackForward.push(
      <div
        className={"slide-page " + (activeSection === 0 ? "disabled" : "")}
        onClick={() => {
          gotoBackSection();
        }}
      >
        {<i className="fa fa-arrow-left"></i>}
      </div>
    );
    slideBackForward.push(
      <div
        className={"slide-page"}
        onClick={() => {
          gotoNextSection();
        }}
      >
        <i className="fa fa-arrow-right"></i>
      </div>
    );
    slideBackForward.push(<div className={"slide-page divider"}></div>);
  }

  if (window.innerWidth > 600) {
    slideBackForward.push(
      <div
        className={"slide-page zoom ml-15p"}
        onClick={() => {
          setZoomLevel((curr) => curr + 0.1);
        }}
      >
        <i className="fa fa-plus" aria-hidden="true"></i>
      </div>
    );
    slideBackForward.push(
      <div className={"slide-page no-border"}>{zoomLevel.toFixed(1)}</div>
    );
    slideBackForward.push(
      <div
        className={"slide-page zoom"}
        onClick={() => {
          setZoomLevel((curr) => curr - 0.1);
        }}
      >
        <i className="fa fa-minus" aria-hidden="true"></i>
      </div>
    );
  }
  const totalSlides = 7; // Total number of pages to display
  const halfSlides = Math.floor(totalSlides / 2); // Half of total pages

  // Calculate the starting and ending slide indices
  let minSlide = window.innerWidth < 600 ? activeSlide - halfSlides : 0;
  let maxSlide =
    window.innerWidth < 600 ? activeSlide + halfSlides + 1 : slides.length; // +1 because slice end index is exclusive

  // Adjust the start and end if they go out of bounds
  if (minSlide < 0) {
    maxSlide -= minSlide; // Increase maxSlide by the negative overflow of minSlide
    minSlide = 0;
  }
  if (maxSlide > slides.length) {
    minSlide -= maxSlide - slides.length; // Decrease minSlide by the overflow of maxSlide
    maxSlide = slides.length;
  }

  minSlide = Math.max(0, minSlide); // Ensure minSlide doesn't go below 0
  maxSlide = Math.min(slides.length, maxSlide); // Ensure maxSlide doesn't exceed total slides

  if (minSlide !== 0) {
    slidePages.push(
      <div
        key={"prev" + (activeSlide - 1)}
        className={"slide-page"}
        onClick={() => {
          setActiveSlide(activeSlide - 1);
          setActiveSection(0);
        }}
      >
        {"<"}
      </div>
    );
  }
  for (let i = minSlide; i < maxSlide; i++) {
    slidePages.push(
      <div
        key={i}
        className={"slide-page " + (activeSlide === i ? "active-slide" : "")}
        onClick={() => {
          setActiveSlide(i);
          setActiveSection(0);
        }}
      >
        {i + 1}
      </div>
    );
  }
  if (maxSlide < slides.length) {
    slidePages.push(
      <div
        key={"next" + (activeSlide + 1)}
        className={"slide-page "}
        onClick={() => {
          setActiveSlide(activeSlide + 1);
          setActiveSection(0);
        }}
      >
        {">"}
      </div>
    );
  }
  if (!isPresenting && status === "done") {
    slidePages.push(
      <div
        key={"edit"}
        className={"slide-page"}
        onClick={() => {
          setEditSlideOpen(true);
        }}
      >
        <img src={editIcon} alt="menuSettingIcon" />
      </div>
    );
  }
  if (status === "wait-unknown") {
    return <Loading2 />;
  }
  if (status === "wait") {
    return (
      <Loading2 loadingText="Hold tight, you’ll see your slides building in approximately 20-30 seconds" />
    );
  }
  if (loading) {
    return <Loading2 />;
  }
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        {slides.map(
          (slide, index) =>
            activeSlide === index && (
              <Slide
                setSortCutKeysActive={setSortCutKeysActive}
                key={slide.id + activeSlide + activeSection}
                sections={slide.sections}
                isVisible={activeSlide === index}
                activeSlide={activeSlide}
                onContentChange={onContentChange}
                activeSection={status === "in progress" ? 99 : activeSection}
                isPresenting={isPresenting}
                slides={slides}
                setSections={(newSections) => {
                  const slidesDataClone = _.cloneDeep(slides);
                  slidesDataClone[activeSlide].sections = newSections;
                  setSlides(slidesDataClone);
                }}
                addSectin={(childIndex, type, isTitle, title, content) => {
                  const slidesDataClone = _.cloneDeep(slides);
                  const newSlide = sectionId++;
                  slidesDataClone[activeSlide].sections.splice(
                    childIndex + 1,
                    0,
                    {
                      id: "section" + newSlide,
                      ...(type === "image"
                        ? { image: content }
                        : { key: title, content: content }),
                      type: type === "text" && isTitle ? "title" : "content",
                    }
                  );
                  setSlides(slidesDataClone);
                  setActiveSection(activeSection + 1);
                }}
                deleteSection={(childIndex) => {
                  const slidesDataClone = _.cloneDeep(slides);
                  slidesDataClone[activeSlide].sections.splice(childIndex, 1);
                  setSlides(slidesDataClone);
                  setActiveSection(activeSection - 1);
                }}
                onAddImageClick={(index) => {
                  setImageUploadDialogOpen(true);
                  setNewImageIndex(index);
                }}
              />
            )
        )}
      </DragDropContext>
      <div className="slide-pagination back-for">{slideBackForward}</div>
      <div className="slide-pagination">
        {status === "in progress" && <BlinkingDotComponent />}
        {slidePages}
      </div>
      {isPresenting && (
        <div
          className="slide-pagination-modes"
          onClick={() => {
            try {
              if (document.exitFullscreen) {
                document.exitFullscreen();
              }
            } catch (err) {
              console.log("error in fullscreen", err);
            }
            navigate(Paths.Lesson.replace(":jobid", jobid));
          }}
        >
          {window.innerWidth > 600 && "Exit Presentation Mode "}
          <img src={slideCloseIcon} alt="slideCloseIcon" />
        </div>
      )}
      {/* {!isPresenting && needToSave && (
        <div
          className="slide-pagination-modes save-changes"
          onClick={() => {
            alert("Add code to save");
          }}
        >
          Save Changes
        </div>
      )} */}
      {!isPresenting && (
        <>
          <div
            className="slide-pagination-modes back"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={leftBlueIcon} alt="leftBlueIcon" />
            <span>{window.innerWidth < 600 ? "Back" : "Back To Overview"}</span>
          </div>
          <div
            className="slide-pagination-modes blue-btn"
            onClick={async () => {
              setLoading(true);
              await saveUnsaveChangesOut();
              try {
                if (!document.fullscreenElement) {
                  document.documentElement.requestFullscreen();
                }
              } catch (err) {
                console.log("error in fullscreen", err);
              }
              navigate(
                Paths.Presentation.replace(":jobid", jobid).replace(
                  ":mode",
                  "present"
                )
              );
            }}
          >
            Start Presenting
            {/* {window.innerWidth < 600 ? "Present" : "Start Presenting"} */}
          </div>
        </>
      )}
      {activeSlide === 0 &&
        activeSection === 0 &&
        isPresenting &&
        status === "done" && (
          <div className="press-to-start">Press space to begin presenting</div>
        )}
      <BuildingInProgressDialog
        open={openBuildingInProgressDialog}
        setOpen={setOpenBuildingInProgressDialog}
      />
      <ImageUploadDialog
        open={imageUploadDialogOpen}
        setOpen={setImageUploadDialogOpen}
        onAddClick={(image_url) => {
          const slidesDataClone = _.cloneDeep(slides);
          const newSection = {
            id: slidesDataClone[activeSlide].sections[
              newImageIndex > -1
                ? newImageIndex
                : slidesDataClone[activeSlide].sections.length
            ].id,
            image: image_url,
            type: "content",
          };
          slidesDataClone[activeSlide].sections[
            newImageIndex > -1
              ? newImageIndex
              : slidesDataClone[activeSlide].sections.length
          ] = newSection;
          setSlides(slidesDataClone);
          // setActiveSection(activeSection + 1);
        }}
      />
      {isEditSlideOpen && (
        <EditSlidesDialog
          open={isEditSlideOpen}
          slides={slides}
          setSlides={setSlides}
          setOpen={setEditSlideOpen}
          onSlideClick={(index) => {
            setActiveSlide(index);
            setActiveSection(0);
          }}
          addNewSlide={() => {
            const slidesDataClone = _.cloneDeep(slides);
            const newSlide = slideId++;
            const newSection = sectionId++;
            const newSlideObj = {
              id: "slide" + newSlide,
              keyId: "Slide " + newSlide,
              sections: [
                {
                  content: "New Slide...",
                  id: "section" + newSection,
                  type: "title",
                },
              ],
            };
            slidesDataClone.push(newSlideObj);
            setSlides(slidesDataClone);
            setActiveSlide(slidesDataClone.length - 1);
          }}
          onTitleChange={(newTitle, slideIndex, titleId, titleIndex) => {
            onContentChange(
              titleIndex,
              newTitle,
              "content",
              false,
              1,
              slideIndex
            );
          }}
        />
      )}
    </>
  );
};

export default Presentation;
