/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";

function Navigation(props) {
  const { isLogin = false, setMenuOpen = () => {} } = props;
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();

  const toggleNavigation = (e) => {
    e.preventDefault();
    setIsOpened(!isOpened);
  };

  useEffect(() => {
    if (setMenuOpen) {
      setMenuOpen(isOpened);
    }
  }, [isOpened]);

  return (
    <div className="navigation">
      <ul className="navigation__list">
        <li>
          <a href="/#features" onClick={() => navigate("/#features")}>
            Features
          </a>
        </li>
        <li>
          <a href="/#pricing" onClick={() => navigate("/#pricing")}>
            Pricing
          </a>
        </li>
        <li>
          <a href="#" onClick={() => navigate(Paths.ContactUs)}>
            Contact
          </a>
        </li>
        {/* <li>
          <a href="/#faq" onClick={() => navigate("/#faq")}>
            FAQs
          </a>
        </li> */}

        <div className="navigation__btns">
          {!isLogin && (
            <a
              href="#"
              onClick={() => navigate(Paths.Login)}
              className="btn btn-isset"
            >
              Log In
            </a>
          )}
          {isLogin && (
            <a
              href="#"
              onClick={() => navigate(Paths.SignUp)}
              className="btn btn-isset"
            >
              Sign Up
            </a>
          )}
        </div>
      </ul>

      <div className={`navigation__mobile ${isOpened ? "open" : ""}`}>
        <button className="navigation__expand" onClick={toggleNavigation}>
          <span></span>
          <span></span>
          <span></span>
        </button>

        <ul className="navigation__list">
          <li>
            <a href="/#features" onClick={() => navigate("/#features")}>
              Features
            </a>
          </li>
          <li>
            <a href="/#pricing" onClick={() => navigate("/#pricing")}>
              Pricing
            </a>
          </li>
          <li>
            <a href="#" onClick={() => navigate(Paths.ContactUs)}>
              Contact
            </a>
          </li>
          {/* <li>
            <a href="/#faq" onClick={() => navigate("/#faq")}>
              FAQs
            </a>
          </li> */}
          {!isLogin && (
            <div className="navigation__btns">
              <a
                href="#"
                onClick={() => navigate(Paths.Login)}
                className="btn btn-isset"
              >
                Log In
              </a>
            </div>
          )}
          {isLogin && (
            <div className="navigation__btns">
              <a
                href="#"
                onClick={() => navigate(Paths.SignUp)}
                className="btn btn-isset"
              >
                Sign up
              </a>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Navigation;
