import "./style.scss";

function Plan({
  isSelected,
  headline,
  description,
  price,
  btnText,
  btnLink,
  benefits,
  onClick = () => {},
  className = "",
}) {
  return (
    <div className={`plan ${isSelected ? "selected" : ""} ${className}`}>
      <div className="plan__content">
        <h5>{headline}</h5>
        <p className="plan__discription">{description}</p>
        <div className="plan__price">
          <h2>{price}</h2>
        </div>
        <ul className="plan__benefits">
          {benefits
            ? benefits.map((benefit, index) => <li key={index}>{benefit}</li>)
            : ""}
        </ul>
      </div>
      <div className="plan__btns">
        <a href={btnLink} onClick={onClick} className="btn btn-less">
          {btnText}
        </a>
      </div>
    </div>
  );
}

export default Plan;
