import * as Yup from "yup";
import { Formik } from "formik";
import { Box, FormHelperText } from "@mui/material";
import _ from "lodash";
import "./style.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  changePassword,
  sendResetPasswordOtp,
  validateOtp,
} from "services/Auth";
import Loading from "component/Loading";
import Header from "component/Header";
import Footer from "component/Footer";
import {
  otpScreenImage,
  resetPasswordImage,
  rightGoldenMark,
  signUpImage,
} from "assets";
import InfoDialog from "dialogs/InfoDialog";
import { Paths } from "routes";

const ForgotPassword = () => {
  const [signUpData, setSignUpData] = useState({ step: 1 });
  const params = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const [loader, setLoader] = useState(false);

  return (
    <div className="forgot-password-wrapper">
      <Header />
      {loader && <Loading />}
      <div className={`forgot-password ${loader ? "ui-hide" : ""}`}>
        {signUpData.step === 1 && (
          <div className="card-wrapper">
            <div className="card">
              <div className="left-section">
                <div className="title mb-12">Reset Password</div>
                <div className="sub-title">
                  Please Enter your Email address that you used to create
                  account and we will send you a OTP (one time password) to rest
                  your password.
                </div>
                <Formik
                  initialValues={{
                    email: params.get("email") || "",
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting, setFieldValue }
                  ) => {
                    try {
                      setLoader(true);
                      if (sendResetPasswordOtp) {
                        const resData = await sendResetPasswordOtp({
                          Email: values.email,
                        });
                        if (
                          resData &&
                          resData.data &&
                          resData.data.result === "OTP sent"
                        ) {
                          setSignUpData({
                            Email: values.email,
                            step: 2,
                          });
                        } else {
                          setErrors({
                            submit: _.get(
                              resData,
                              "data.result",
                              "Something went wrong"
                            ),
                          });
                        }
                        setFieldValue("hCaptcha", "");
                      }
                    } catch (err) {
                      console.error(err);
                      setStatus({ success: false });
                      setErrors({
                        submit: _.get(
                          err,
                          "response.data.title",
                          "Something went wrong"
                        ),
                      });

                      setSubmitting(false);
                    } finally {
                      setLoader(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div className="mt-20">
                        <input
                          placeholder="Email Address"
                          name="email"
                          className="ui-input"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                        />
                        {Boolean(touched.email && errors.email) && (
                          <FormHelperText error>
                            {touched.email && errors.email}
                          </FormHelperText>
                        )}
                      </div>
                      {errors.submit && (
                        <Box>
                          <FormHelperText error className="text-center">
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <Box mt={4}>
                        <button
                          className="btn-login hover-zoom"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Send OTP
                        </button>
                      </Box>
                    </form>
                  )}
                </Formik>
                <div className="links">
                  <div
                    className="link"
                    onClick={() => {
                      navigate(Paths.SignUp);
                    }}
                  >
                    Create Account?
                  </div>
                </div>
              </div>
              <div className="right-section">
                <img src={resetPasswordImage} alt="signUpImage" />
              </div>
            </div>
          </div>
        )}{" "}
        {signUpData.step === 2 && (
          <div className="card-wrapper">
            <div className="card ">
              <div className="left-section">
                <div className="title mb-12">We’ve just sent you a code</div>
                <div className="sub-title">
                  Please check your email (including spam) to find the code we
                  have just sent and paste it below to begin using Lessen.
                </div>
                <input
                  className="otp ui-input"
                  placeholder={`Code sent to ${signUpData.Email}`}
                  onChange={(e) => {
                    if (
                      ((!isNaN(e.target.value) &&
                        Number.isInteger(parseFloat(e.target.value))) ||
                        _.isEmpty(e.target.value)) &&
                      e.target.value.length <= 6
                    ) {
                      setOtp(e.target.value);
                    }
                  }}
                  value={otp}
                />
                <FormHelperText error>{otpError && otpError}</FormHelperText>
                <Box mt={2}>
                  <button
                    className="btn-login hover-zoom"
                    type="submit"
                    disabled={otp.length !== 6 || isLoading}
                    onClick={async () => {
                      try {
                        setLoader(true);
                        if (validateOtp) {
                          setIsLoading(true);
                          const resData = await validateOtp({
                            Email: signUpData.Email,
                            Otp: otp,
                          });
                          if (
                            resData &&
                            resData.data &&
                            resData.data.result === "OTP Valid"
                          ) {
                            setIsLoading(false);
                            setSignUpData({
                              ...signUpData,
                              step: 3,
                              Otp: otp,
                            });
                          }
                          setIsLoading(false);
                        }
                      } catch (err) {
                        console.error(err);
                        setIsLoading(false);
                        setOtpError(
                          _.get(
                            err,
                            "response.data.title",
                            "Something went wrong"
                          )
                        );
                      } finally {
                        setLoader(false);
                      }
                    }}
                  >
                    {isLoading ? "Verifying..." : "Verify"}
                  </button>
                </Box>
              </div>
              <div className="right-section">
                <img src={otpScreenImage} alt="otpScreenImage" />
              </div>
            </div>
          </div>
        )}
        {signUpData.step === 3 && (
          <div className="card-wrapper">
            <div className="card">
              <div className="left-section">
                <div className="title mb-12">Set Password</div>
                <div className="sub-title">
                  Please enter New Password and Confirm New Password in order to
                  create new password.
                </div>
                <Formik
                  initialValues={{
                    password: "",
                    confirmpassword: "",
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .min(6)
                      .max(255)
                      .required("Password is required"),
                    confirmpassword: Yup.string()
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      )
                      .required("Confirm Password is required"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting, setFieldValue }
                  ) => {
                    try {
                      setLoader(true);
                      if (changePassword) {
                        const resData = await changePassword({
                          Password: values.password,
                          Email: signUpData.Email,
                          Otp: signUpData.Otp,
                        });

                        if (
                          resData &&
                          resData.data &&
                          resData.data.result === "Password changed"
                        ) {
                          setInfoDialogOpen(true);
                        }
                      }
                    } catch (err) {
                      console.error(err);
                      setStatus({ success: false });
                      setErrors({
                        submit: _.get(
                          err,
                          "response.data.title",
                          "Something went wrong"
                        ),
                      });
                      setSubmitting(false);
                    } finally {
                      setLoader(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div className="mt-20">
                        <input
                          type="password"
                          placeholder="New Password"
                          className="ui-input"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                        />
                        {Boolean(touched.password && errors.password) && (
                          <FormHelperText error>
                            {touched.password && errors.password}
                          </FormHelperText>
                        )}
                      </div>
                      <div className="mt-20 ">
                        <input
                          type="password"
                          placeholder="Confirm New Password"
                          className="ui-input"
                          name="confirmpassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmpassword}
                        />
                        {Boolean(
                          touched.confirmpassword && errors.confirmpassword
                        ) && (
                          <FormHelperText error>
                            {touched.confirmpassword && errors.confirmpassword}
                          </FormHelperText>
                        )}
                      </div>

                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error className="text-center">
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}

                      <Box mt={2}>
                        <button
                          className="btn-login hover-zoom"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Reset Password
                        </button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </div>
              <div className="right-section">
                <img src={signUpImage} alt="signUpImage" />
              </div>
            </div>
          </div>
        )}
      </div>
      <InfoDialog
        open={infoDialogOpen}
        setOpen={(newValue) => {
          if (!newValue) navigate(Paths.Login);
          setInfoDialogOpen(newValue);
        }}
        text="Password Changed Successfully!"
        btnText="Okay"
        image={rightGoldenMark}
      />
      <Footer className={`${loader ? "ui-hide" : ""}`} />
    </div>
  );
};

export default ForgotPassword;
