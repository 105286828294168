import Navigation from "component/Navigation/Navigation";
import "utils/landingStyle.scss";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Header(props) {
  const { isLogin = false } = props;
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();

  const container = (
    <div className="container">
      <a href="#" onClick={() => navigate("/")} className="logo">
        Lessen AI
      </a>
      <Navigation isLogin={isLogin} setMenuOpen={setIsOpened}></Navigation>
    </div>
  );

  return (
    <div className="landing-style">
      {isOpened && <div className="mobile-header-placeholder"></div>}
      <header className={`header ${isOpened ? "menu-open" : ""}`} key="header">
        {container}
      </header>
    </div>
  );
}

export default Header;
