import React from "react";
import { useState } from "react";
import ImageViewer from "react-images-viewer";

function ImagePreview(props) {
  const { src, className = "content-img" } = props;
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };
  return (
    <>
      <img
        src={src}
        alt="src"
        className={className}
        onClick={() => {
          setIsViewerOpen(true);
        }}
      ></img>
      <ImageViewer
        imgs={[{ src: src }]}
        isOpen={isViewerOpen}
        onClose={closeImageViewer}
        onClickImg={() => setIsViewerOpen(true)}
        className="image-viewer"
      />
    </>
  );
}

export default ImagePreview;
