import React from "react";

export const LoadingInline = ({ loadingText = "Loading..." }) => {
  const iconClasses = [
    "fas fa-atom",
    "fas fa-magnet",
    "fas fa-globe",
    "fas fa-lightbulb",
    "fas fa-square-root-alt",
    "fas fa-calculator",
    "fas fa-divide",
    "fas fa-infinity",
    "fas fa-balance-scale",
    "fas fa-chart-line",
    "fas fa-file-invoice-dollar",
    "fas fa-coins",
    "fas fa-flask",
    "fas fa-vial",
    "fas fa-radiation",
    "fas fa-microscope",
  ];

  function getRandomIcons(n) {
    let result = [];
    let iconClassesCopy = [...iconClasses]; // Create a copy of the array to not mutate the original one

    for (let i = 0; i < n; i++) {
      let randomIndex = Math.floor(Math.random() * iconClassesCopy.length);
      result.push(iconClassesCopy[randomIndex]);
      iconClassesCopy.splice(randomIndex, 1); // Remove the selected item from the array
    }

    return result;
  }

  const icons = getRandomIcons(3);
  return (
    <>
      <div className="loading">
        {icons.map((fa) => (
          <>
            <i className={fa + " icon"}></i>
          </>
        ))}
      </div>
      <p className="loading-text">{loadingText}</p>
    </>
  );
};

function Loading2({ loadingText = "Loading..." }) {
  return (
    <>
      <div className="loading-container">
        <LoadingInline loadingText={loadingText} />
      </div>
    </>
  );
}

export default Loading2;
