import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLesson } from "services/AI";
import _ from "lodash";
import Loading from "component/Loading";
import { saveAs } from "file-saver";
import HtmlDocx from "html-docx-js/dist/html-docx";
import { pdfIcon, wordIcon } from "assets";
import { pdfExport } from "pages/StudyWorksheet";

let fetchIntervalLesson = null;
function LessonHomework() {
  let { jobid } = useParams();
  const [data, setData] = useState({});
  const [status, setStatus] = useState("wait");
  const [loading, setLoading] = useState(true);
  const [openBuildingInProgressDialog, setOpenBuildingInProgressDialog] =
    useState(false);

  useEffect(() => {
    if (status === "done") {
      if (openBuildingInProgressDialog) setOpenBuildingInProgressDialog(false);
    }
    if (status === "in progress") {
      setOpenBuildingInProgressDialog(true);
    }
  }, [status]);

  const getData = async () => {
    await getLesson(jobid).then((res) => {
      setData(res.data);
      setStatus(_.get(res, "data.status", ""));
      if (_.get(res, "data.status", "") === "done") {
        if (fetchIntervalLesson) {
          clearInterval(fetchIntervalLesson);
        }
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getData();
    fetchIntervalLesson = setInterval(() => {
      getData();
    }, 3000); // 5sec
    return () => {
      if (fetchIntervalLesson) {
        clearInterval(fetchIntervalLesson);
      }
    };
  }, []);

  if (loading) return <Loading />;

  const handleExportWord = async () => {
    const input = document.getElementById("page-content");

    const contentHTML = input.innerHTML;

    var converted = HtmlDocx.asBlob(contentHTML);
    saveAs(
      converted,
      `Homework - ${_.get(data, "lessenDetails.title", "")}.docx`
    );
  };

  const styleTable = {
    width: "100%",
    borderCollapse: "collapse",
    fontSize: "16px",
    pageBreakInside: "avoid",
    boxSizing: "border-box",
  };

  const styleTh = {
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "8px",
    border: "1px solid #000000",
    webkitPrintColorAdjust: "exact",
    height: "3em",
    lineHeight: "1.5em",
    minHeight: "3em",
    boxSizing: "border-box",
  };

  const styleTd = {
    padding: "8px",
    border: "1px solid #000000",
    fontSize: "16px",
    pageBreakInside: "avoid",
    pageBreakAfter: "auto",
    height: "3em",
    lineHeight: "1.5em",
    minHeight: "3em",
    boxSizing: "border-box",
  };

  const styleTr = {
    pageBreakInside: "avoid",
    pageBreakAfter: "auto",
    boxSizing: "border-box",
  };

  const styleP0 = {
    padding: "0px",
    boxSizing: "border-box",
  };

  const styleMsqWrapper = {
    display: "flex",
    textAlign: "left",
    minHeight: "3em",
    boxSizing: "border-box",
    padding: "1px",
  };

  const styleQTitle = {
    padding: "5px",
    width: "125px",
    textAlign: "center",
    overflow: "hidden",
    boxSizing: "border-box",
  };

  const styleMcqQ = {
    padding: "5px",
    flex: 1,
  };

  const styleGreenColor = {
    background: "#d2fcd7",
  };
  const styleTableWrapper = {
    padding: "20px 0px",
  };

  const pageBreakStyle = {
    pageBreakBefore: "always",
  };

  const homework = _.get(data, "homework.content", {});
  const keyTerms = _.shuffle(
    _.get(homework, "key_terms", []).map((k) => _.get(k, "term", ""))
  );
  const keyDefinition = _.shuffle(
    _.get(homework, "key_terms", []).map((k) => _.get(k, "definition", ""))
  );

  return (
    <>
      <div className="text-center" style={{ marginTop: "8px" }}>
        <label htmlFor="">Download as: </label>
        <img
          src={pdfIcon}
          onClick={() => {
            setLoading(true);
            pdfExport(
              `Homework - ${_.get(data, "lessenDetails.title", "")}.pdf`
            ).then(() => setLoading(false));
          }}
          alt="pdf"
          className="cursor-pointer"
          height={28}
        />
        <img
          src={wordIcon}
          onClick={() => handleExportWord()}
          alt="word"
          className="cursor-pointer"
          height={28}
        />
      </div>
      <div
        id="page-content"
        style={{
          width: "210mm",
          minWidth: "210mm",
          margin: "auto",
          fontFamily: "'Open Sans', sans-serif",
          padding: "0px 30px",
          color: "#000000",
          letterSpacing: "normal",
        }}
      >
        <h1
          style={{
            fontSize: "2em",
            fontWeight: "bold",
            fontFamily: "'Open Sans', sans-serif",
            textAlign: "center",
          }}
        >
          {_.get(data, "lessenDetails.title", "")}
        </h1>
        <div style={{ ...styleTableWrapper, paddingTop: "0px" }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Match The Definition To The Key Term
          </h2>
          <div style={{ border: "1px solid rgb(0, 0, 0)", padding: "15px" }}>
            {keyTerms.map((term, index) => (
              <>
                <div
                  style={{
                    display: "flex",
                    marginBottom:
                      index + 1 !== keyTerms.length ? "35px" : "0px",
                    fontWeight: "bold",
                  }}
                >
                  <div style={{ width: "60%" }}>{term}</div>
                  <div style={{ width: "40%" }}>{keyDefinition[index]}</div>
                </div>
              </>
            ))}
          </div>
        </div>
        <div style={{ ...styleTableWrapper, ...pageBreakStyle }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Knowledge Retrieval
          </h2>

          {_.get(homework, "retrieval_knowledge", []).map((mcq, index) => (
            <>
              <table style={{ ...styleTable }}>
                <tr style={{ ...styleTr }}>
                  <th style={{ ...styleTh, ...styleP0 }}>
                    <div style={{ ...styleMsqWrapper }}>
                      <div style={{ ...styleQTitle, ...styleGreenColor }}>
                        Question #{index + 1}
                      </div>
                      <div style={{ ...styleMcqQ }}>{mcq}</div>
                    </div>
                  </th>
                </tr>
                <tr style={{ ...styleTr }}>
                  <td style={{ ...styleTd }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </td>
                </tr>
              </table>
            </>
          ))}
        </div>
        <div style={{ ...styleTableWrapper, ...pageBreakStyle }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Activities
          </h2>
          {_.get(homework, "activities", []).map((mcq, index) => (
            <>
              <table style={{ ...styleTable }}>
                <tr style={{ ...styleTr }}>
                  <th style={{ ...styleTh, ...styleP0 }}>
                    <div style={{ ...styleMsqWrapper }}>
                      <div style={{ ...styleQTitle, ...styleGreenColor }}>
                        Activity #{index + 1}
                      </div>
                      <div style={{ ...styleMcqQ }}>{mcq}</div>
                    </div>
                  </th>
                </tr>
              </table>
            </>
          ))}
        </div>
        <div style={{ ...styleTableWrapper, ...pageBreakStyle }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Quiz Questions
          </h2>

          {_.get(homework, "knowledge_test", []).map((mcq, index) => (
            <>
              <table style={{ ...styleTable }}>
                <tr style={{ ...styleTr }}>
                  <th style={{ ...styleTh, ...styleP0 }}>
                    <div style={{ ...styleMsqWrapper }}>
                      <div style={{ ...styleQTitle, ...styleGreenColor }}>
                        Question #{index + 1}
                      </div>
                      <div style={{ ...styleMcqQ }}>{mcq}</div>
                    </div>
                  </th>
                </tr>
                <tr style={{ ...styleTr }}>
                  <td style={{ ...styleTd }}>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </td>
                </tr>
              </table>
            </>
          ))}
        </div>
        <div style={{ ...styleTableWrapper, ...pageBreakStyle }}>
          <h2
            style={{
              fontSize: "1.5em",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Exam Practice Questions
          </h2>
          {_.get(homework, "exam_practice", []).map((mcq, index) => (
            <>
              <table style={{ ...styleTable }}>
                <tr style={{ ...styleTr }}>
                  <th style={{ ...styleTh, ...styleP0 }}>
                    <div style={{ ...styleMsqWrapper }}>
                      <div style={{ ...styleQTitle, ...styleGreenColor }}>
                        Question #{index + 1}
                      </div>
                      <div style={{ ...styleMcqQ }}>{mcq}</div>
                    </div>
                  </th>
                </tr>
              </table>
            </>
          ))}
        </div>

        <div
          style={{ textAlign: "center", padding: "15px", marginBottom: "15px" }}
        >
          Generated on{" "}
          <a
            href="https://lessen.ai/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#0075ff", textDecoration: "none" }}
          >
            lessen.ai
          </a>
        </div>
      </div>
    </>
  );
}

export default LessonHomework;
