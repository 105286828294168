import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { leftBlueIcon, whitePlusIcon } from "assets";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import { MenuItem, Select } from "@mui/material";
import { updateUserRegion } from "services/Auth";
import AuthContext from "contexts/authContext";
import _ from "lodash";

function PageLayout(props) {
  const {
    title,
    children,
    className,
    hasBack = false,
    hasRegion = false,
    hasCreate = false,
    onBackClick,
    hasMaxWidth = false,
  } = props;
  const navigate = useNavigate();
  const { auth, changeAuth } = useContext(AuthContext);

  useEffect(() => {
    setRegion(_.get(auth, "userData.Region", "uk"));
  }, [auth]);

  const [region, setRegion] = useState(_.get(auth, "userData.Region", "uk"));
  return (
    <div className={`page-layout ${className}`}>
      <div
        className={`page-title  ${hasMaxWidth ? "has-max-width" : ""} ${
          hasBack ? "p-60 has-back" : ""
        }`}
      >
        {hasBack && (
          <div
            className="back"
            onClick={() => {
              if (onBackClick) onBackClick();
            }}
          >
            <img src={leftBlueIcon} alt="leftBlueIcon" />
            <span>Back</span>
          </div>
        )}

        {title}
        {hasCreate && (
          <div
            className="create-lesson-btn blue-btn"
            onClick={() => navigate(Paths.CreateLesson)}
          >
            <img src={whitePlusIcon} alt="whitePlusIcon" />
            <span>Create Lesson</span>
          </div>
        )}
        {hasRegion && (
          <div
            className="region"
            onClick={() => {
              if (onBackClick) onBackClick();
            }}
          >
            <Select
              value={region}
              name="Region"
              className="select"
              inputProps={{ "aria-label": "Without label" }}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setRegion(value);
                updateUserRegion(value);
                changeAuth({
                  ...auth,
                  userData: { ...auth.userData, Region: value },
                });
              }}
            >
              <MenuItem key={"uk"} value={"uk"}>
                United Kingdom
              </MenuItem>
              <MenuItem key={"us"} value={"us"}>
                United States
              </MenuItem>
              <MenuItem key={"europe"} value={"eu"}>
                Europe
              </MenuItem>
            </Select>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export default PageLayout;
