import axios from "axios";
import _ from "lodash";
// import { Paths } from "routes";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      _.get(error, "response.data.code", "") === "authentication_error" ||
      _.get(error, "response.data.code", "") === "expired_token" ||
      _.get(error, "response.data.code", "") ===
        "invalid_or_unauthorized_lesson_id"
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("undoIntroDone");
      localStorage.removeItem("AuthContext");
      window.location.href = "/";
    }
    // if (
    //   _.get(error, "response.data.code", "") === "otp_verification_required"
    // ) {
    //   window.location.href = Paths.otpVerifying;
    // }
    return Promise.reject(error);
  }
);

export const axiosInstanceNoLoader = axios.create();

axiosInstanceNoLoader.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
