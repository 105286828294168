import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLesson } from "services/AI";
import _ from "lodash";
import Loading from "component/Loading";
import markdownit from "markdown-it";
import { saveAs } from "file-saver";
import HtmlDocx from "html-docx-js/dist/html-docx";
import { pdfIcon, wordIcon } from "assets";
import { pdfExport } from "pages/StudyWorksheet";

const md = markdownit({ html: false, linkify: true, typographer: true });

let fetchIntervalLesson = null;
function StudyMaterial() {
  let { jobid } = useParams();
  const [slideData, setSlideData] = useState({ slides: [] });
  const [status, setStatus] = useState("wait");
  const [loading, setLoading] = useState(true);
  const [openBuildingInProgressDialog, setOpenBuildingInProgressDialog] =
    useState(false);

  useEffect(() => {
    if (status === "done") {
      if (openBuildingInProgressDialog) setOpenBuildingInProgressDialog(false);
    }
    if (status === "in progress") {
      setOpenBuildingInProgressDialog(true);
    }
  }, [status]);

  const getSlideData = async () => {
    await getLesson(jobid).then((res) => {
      setSlideData(res.data);
      setStatus(_.get(res, "data.status", ""));
      if (_.get(res, "data.status", "") === "done") {
        if (fetchIntervalLesson) {
          clearInterval(fetchIntervalLesson);
        }
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    getSlideData();
    fetchIntervalLesson = setInterval(() => {
      getSlideData();
    }, 3000); // 5sec
    return () => {
      if (fetchIntervalLesson) {
        clearInterval(fetchIntervalLesson);
      }
    };
  }, []);

  if (loading) return <Loading />;

  const handleExportWord = async () => {
    const input = document.getElementById("page-content");

    const contentHTML = input.innerHTML;

    var converted = HtmlDocx.asBlob(contentHTML);
    saveAs(
      converted,
      `Guide - ${_.get(slideData, "lessenDetails.title", "")}.docx`
    );
  };
  return (
    <>
      <div className="text-center">
        <label htmlFor="">Download as: </label>
        <img
          src={pdfIcon}
          // onClick={() => handleExportPDFNew()}
          onClick={() => {
            setLoading(true);
            pdfExport(
              `Guide - ${_.get(slideData, "lessenDetails.title", "")}.pdf`
            ).then(() => setLoading(false));
          }}
          alt="pdf"
          className="cursor-pointer"
          height={28}
        />
        <img
          src={wordIcon}
          onClick={() => handleExportWord()}
          alt="word"
          className="cursor-pointer"
          height={28}
        />
      </div>
      <div
        style={{
          padding: "0px 15px",
          maxWidth: "210mm",
          // border: "1px solid black",
          margin: "15px auto",
          // borderRadius: "10px",
        }}
        id="page-content"
      >
        <div
          className="lesson-body"
          style={{
            // maxWidth: "210mm",
            border: "2px solid #2baf30",
            // margin: "15px auto",
            borderRadius: "10px",
          }}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: `html, address,
blockquote,
body, dd, div,
dl, dt, fieldset, form,
frame, frameset,
h1, h2, h3, h4,
h5, h6, noframes,
ol, p, ul, center,
dir, hr, menu, pre   { display: block; unicode-bidi: embed;  }
li              { display: list-item }
head            { display: none }
table           { display: table }
tr              { display: table-row }
thead           { display: table-header-group }
tbody           { display: table-row-group }
tfoot           { display: table-footer-group }
col             { display: table-column }
colgroup        { display: table-column-group }
td, th          { display: table-cell }
caption         { display: table-caption }
th              { font-weight: bolder; text-align: center }
caption         { text-align: center }
body            { margin: 8px }
h1              { font-size: 2em; margin: .67em 0 }
h2              { font-size: 1.5em; margin: .75em 0 }
h3              { font-size: 1.17em; margin: .83em 0 }
h4, p,
blockquote, ul,
fieldset, form,
ol, dl, dir,
menu            { margin: 1.12em 0 }
h5              { font-size: .83em; margin: 1.5em 0 }
h6              { font-size: .75em; margin: 1.67em 0 }
h1, h2, h3, h4,
h5, h6, b,
strong          { font-weight: bolder }
blockquote      { margin-left: 40px; margin-right: 40px }
i, cite, em,
var, address    { font-style: italic }
pre, tt, code,
kbd, samp       { font-family: monospace }
pre             { white-space: pre }
button, textarea,
input, select   { display: inline-block }
big             { font-size: 1.17em }
small, sub, sup { font-size: .83em }
sub             { vertical-align: sub }
sup             { vertical-align: super }
table           { border-spacing: 2px; }
thead, tbody,
tfoot           { vertical-align: middle }
td, th, tr      { vertical-align: inherit }
s, strike, del  { text-decoration: line-through }
hr              { border: 1px inset }
ol, ul, dir,
menu, dd        { margin-left: 40px }
ol              { list-style-type: decimal }
ol ul, ul ol,
ul ul, ol ol    { margin-top: 0; margin-bottom: 0 }
u, ins          { text-decoration: underline }
br:before       { content: "A"; white-space: pre-line }
center          { text-align: center }
:link, :visited { text-decoration: underline }
:focus          { outline: thin dotted invert }

/* Begin bidirectionality settings (do not change) */
BDO[DIR="ltr"]  { direction: ltr; unicode-bidi: bidi-override }
BDO[DIR="rtl"]  { direction: rtl; unicode-bidi: bidi-override }

*[DIR="ltr"]    { direction: ltr; unicode-bidi: embed }
*[DIR="rtl"]    { direction: rtl; unicode-bidi: embed }
* {
  font-family: "Open Sans", sans-serif;
}
h1, h2, h3, h4,
h5, h6, strong { font-weight: bolder; color: #2baf30 }
.lesson-body {
    padding: 0px 20px;
}
hr { border-color: #2baf30;}
`,
            }}
          ></style>

          <div
            dangerouslySetInnerHTML={{
              __html: md.render(slideData.studyMaterial.content),
            }}
          />
          <div style={{ textAlign: "center", padding: "15px" }}>
            Generated on{" "}
            <a
              href="https://lessen.ai/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#0075ff", textDecoration: "none" }}
            >
              lessen.ai
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default StudyMaterial;
