import Loading from "component/Loading";
import AuthContext from "contexts/authContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";

function getRandomLogoutMessage() {
  const messages = [
    "Taking attendance for your logout—just a moment!",
    "Grading your session as complete, logging out...",
    "Time to close the digital classroom, stand by.",
    "The bell has rung on your session, signing you out.",
    "Packing up your digital lesson plan—logging out.",
    "Marking your digital presence as 'Logged Out', please wait.",
    "Raising the curtain on your logout scene.",
    "Your teaching session is adjourned—signing you out.",
    "Class dismissed! Logging you out safely.",
    "Taking a brief recess—logging you out.",
  ];

  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}

function LogOut() {
  const navigate = useNavigate();
  const { changeAuth } = useContext(AuthContext);
  const logoutMessage = getRandomLogoutMessage();

  useEffect(() => {
    setTimeout(() => {
      changeAuth({ isLoggedIn: false, userData: {} });
      navigate(Paths.Login);
    }, 2500);
  }, []);
  return <Loading text={logoutMessage} />;
}

export default LogOut;
