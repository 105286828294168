import { useEffect, useContext } from "react";
import { getUser } from "services/Auth";
import AuthContext from "contexts/authContext";
import { Paths } from "routes";
// import { useNavigate } from "react-router-dom";

const AppInit = () => {
  const { auth, changeAuth } = useContext(AuthContext);
  let lessonData = null;
  const anyLessonBeforePay = localStorage.getItem("hasLessonBeforePay");
  if (anyLessonBeforePay) {
    const lessonSpec = localStorage.getItem("lessonSpec");
    if (lessonSpec) lessonData = JSON.parse(lessonSpec);
  }
  // const navigate = useNavigate();

  const getUserData = async () => {
    try {
      let res = await getUser();
      changeAuth({
        isLoggedIn: true,
        userData: { ...auth.userData, ...res.data },
      });
    } catch {
      changeAuth({ isLoggedIn: false, userData: {} });
      window.location.href = Paths.Login;
    }
  };

  useEffect(() => {
    if (auth.isLoggedIn) {
      getUserData();
      // window.location.href = Paths.CreateLesson;
    }
  }, [auth.userData.token]);

  useEffect(() => {
    if (lessonData) {
      // navigate(Paths.CreateLesson);
      // window.location.href = Paths.CreateLesson;
    }
  }, []);

  return <></>;
};

export default AppInit;
