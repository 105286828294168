import * as Yup from "yup";
import { Formik } from "formik";
import { Box, FormHelperText } from "@mui/material";
import _ from "lodash";
import "./style.css";
import { useContext, useRef, useState } from "react";
import AuthContext from "contexts/authContext";
import { useNavigate } from "react-router-dom";
import { senOtpService, signUpService } from "services/Auth";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Paths } from "routes";
import Loading from "component/Loading";
import Header from "component/Header";
import Footer from "component/Footer";
import { otpScreenImage, signUpImage } from "assets";

const SignUp = () => {
  const { changeAuth } = useContext(AuthContext);
  const [signUpData, setSignUpData] = useState({});
  const params = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef(null);
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const [loader, setLoader] = useState(false);

  return (
    <div className="sign-up-wrapper">
      <Header />
      {loader && <Loading />}
      <div className={`sign-up ${loader ? "ui-hide" : ""}`}>
        {_.isEmpty(signUpData) ? (
          <div className="card-wrapper">
            <div className="card">
              <div className="left-section">
                <div className="title">Sign Up</div>
                <Formik
                  initialValues={{
                    firstname: "",
                    lastname: "",
                    email: params.get("email") || "",
                    password: "",
                    confirmpassword: "",
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    firstname: Yup.string()
                      .max(255)
                      .required("First name is required"),
                    lastname: Yup.string()
                      .max(255)
                      .required("Last name is required"),
                    email: Yup.string()
                      .email("Must be a valid email")
                      .max(255)
                      .required("Email is required"),
                    password: Yup.string()
                      .min(6)
                      .max(255)
                      .required("Password is required"),
                    confirmpassword: Yup.string()
                      .oneOf(
                        [Yup.ref("password"), null],
                        "Passwords must match"
                      )
                      .required("Confirm Password is required"),
                    hCaptcha: Yup.string().required("Captcha is required"),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting, setFieldValue }
                  ) => {
                    try {
                      setLoader(true);
                      if (senOtpService) {
                        // const token = await executeRecaptcha("SignUp");
                        const userData = await senOtpService({
                          FirstName: values.firstname,
                          LastName: values.lastname,
                          Password: values.password,
                          Email: values.email,
                          CaptchaCode: values.hCaptcha,
                        });

                        if (userData) {
                          setSignUpData({
                            FirstName: values.firstname,
                            LastName: values.lastname,
                            Password: values.password,
                            Email: values.email,
                          });
                        }
                        captchaRef.current?.resetCaptcha();
                        setFieldValue("hCaptcha", "");
                      }
                    } catch (err) {
                      console.error(err);
                      captchaRef.current?.resetCaptcha();
                      setStatus({ success: false });
                      setErrors({
                        submit: _.get(
                          err,
                          "response.data.title",
                          "Something went wrong"
                        ),
                      });

                      setSubmitting(false);
                    } finally {
                      setLoader(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div className="name-wrapper">
                        <div className="fname">
                          <input
                            placeholder="First Name"
                            className="ui-input"
                            name="firstname"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstname}
                          />
                          {Boolean(touched.firstname && errors.firstname) && (
                            <FormHelperText error>
                              {touched.firstname && errors.firstname}
                            </FormHelperText>
                          )}
                        </div>
                        <div className="lname">
                          <input
                            placeholder="Last Name"
                            name="lastname"
                            className="ui-input"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastname}
                          />
                          {Boolean(touched.lastname && errors.lastname) && (
                            <FormHelperText error>
                              {touched.lastname && errors.lastname}
                            </FormHelperText>
                          )}
                        </div>
                      </div>
                      <div className="mt-20">
                        <input
                          placeholder="Email"
                          name="email"
                          className="ui-input"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                        />
                        {Boolean(touched.email && errors.email) && (
                          <FormHelperText error>
                            {touched.email && errors.email}
                          </FormHelperText>
                        )}
                      </div>
                      <div className="mt-20">
                        <input
                          type="password"
                          placeholder="Password"
                          className="ui-input"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                        />
                        {Boolean(touched.password && errors.password) && (
                          <FormHelperText error>
                            {touched.password && errors.password}
                          </FormHelperText>
                        )}
                      </div>
                      <div className="mt-20 ">
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          className="ui-input"
                          name="confirmpassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmpassword}
                        />
                        {Boolean(
                          touched.confirmpassword && errors.confirmpassword
                        ) && (
                          <FormHelperText error>
                            {touched.confirmpassword && errors.confirmpassword}
                          </FormHelperText>
                        )}
                      </div>
                      <div className="mt-20">
                        <div className="m-a text-center">
                          <HCaptcha
                            ref={captchaRef}
                            sitekey="e0babe09-2202-4ea1-9882-55edfc867418"
                            onVerify={(token) => {
                              setFieldValue("hCaptcha", token);
                            }}
                            onExpire={() => {
                              setFieldValue("hCaptcha", "");
                            }}
                          />
                          {Boolean(errors.hCaptcha) && (
                            <FormHelperText
                              error
                              style={{ maxWidth: "300px", margin: "auto" }}
                            >
                              {errors.hCaptcha}
                            </FormHelperText>
                          )}
                        </div>
                      </div>
                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error className="text-center">
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <div className="tnc-note">
                        By signing up, you are agreeing to our{" "}
                        <a
                          href="https://app.termly.io/document/terms-of-service/cafa1dd8-d321-4cbb-a207-0153baf81b0f"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms and Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://app.termly.io/document/privacy-policy/67de1ba7-ed4b-4bed-8a55-7326b901fc5a"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </div>
                      <Box mt={2}>
                        <button
                          className="btn-login hover-zoom"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Sign Up
                        </button>
                      </Box>
                    </form>
                  )}
                </Formik>
              </div>
              <div className="right-section">
                <img src={signUpImage} alt="signUpImage" />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="card-wrapper">
              <div className="card ">
                <div className="left-section">
                  <div className="title mb-12">We’ve just sent you a code</div>
                  <div className="sub-title">
                    Please check your email (including spam) to find the code we
                    have just sent and paste it below to begin using Lessen.
                  </div>
                  <input
                    className="otp ui-input"
                    placeholder={`Code sent to ${signUpData.Email}`}
                    onChange={(e) => {
                      if (
                        ((!isNaN(e.target.value) &&
                          Number.isInteger(parseFloat(e.target.value))) ||
                          _.isEmpty(e.target.value)) &&
                        e.target.value.length <= 6
                      ) {
                        setOtp(e.target.value);
                      }
                    }}
                    value={otp}
                  />
                  <FormHelperText error>{otpError && otpError}</FormHelperText>
                  <Box mt={2}>
                    <button
                      className="btn-login hover-zoom"
                      type="submit"
                      disabled={otp.length !== 6 || isLoading}
                      onClick={async () => {
                        try {
                          setLoader(true);
                          if (signUpService) {
                            setIsLoading(true);
                            const userData = await signUpService({
                              ...signUpData,
                              Otp: otp,
                            });
                            if (userData) {
                              setIsLoading(false);
                              changeAuth({
                                isLoggedIn: true,
                                userData: userData.data,
                              });
                              navigate({
                                pathname: Paths.SignUpDone,
                                state: { isRedirectFromRegister: true },
                              });
                              try {
                                window.ttq.track("CompleteRegistration", {
                                  contents: [],
                                });
                              } catch (err) {
                                console.log("");
                              }
                            }
                            setIsLoading(false);
                          }
                        } catch (err) {
                          console.error(err);
                          setIsLoading(false);
                          setOtpError(
                            _.get(
                              err,
                              "response.data.title",
                              "Something went wrong"
                            )
                          );
                        } finally {
                          setLoader(false);
                        }
                      }}
                    >
                      {isLoading ? "Verifying..." : "Verify"}
                    </button>
                  </Box>
                </div>
                <div className="right-section">
                  <img src={otpScreenImage} alt="otpScreenImage" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer className={`${loader ? "ui-hide" : ""}`} />
    </div>
  );
};

export default SignUp;
