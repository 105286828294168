export function timeDifferenceAgo(dateString) {
  const currentDate = new Date();
  const pastDate = new Date(dateString);
  const diffInSeconds = Math.floor((currentDate - pastDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} sec ago`;
  } else if (diffInSeconds < 3600) {
    return `${Math.floor(diffInSeconds / 60)} min ago`;
  } else if (diffInSeconds < 86400) {
    return `${Math.floor(diffInSeconds / 3600)}h ago`;
  } else if (diffInSeconds < 172800) {
    return "yesterday";
  } else if (diffInSeconds < 604800) {
    // 7 days
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  } else {
    return `${Math.floor(diffInSeconds / 604800)} weeks ago`;
  }
}
